
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import Pagination from '../../../components/Pagination'

const USERS_URL = '/admin/users'

export default function UserLists() {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()

  const [listAdmins, setListAdmins] = useState([])  
  const [listEditors, setListEditors] = useState([])
  const [listAuthors, setListAuthors] = useState([])
  const [listUsers, setListUsers] = useState([]);  
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1); 

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(USERS_URL + '?page=' + page).then((response)=>{
      if(isMounted){
        setListAdmins(response.data.admins);
        setListEditors(response.data.editors);
        setListAuthors(response.data.authors);
        setListUsers(response.data.docs);
        setTotalPages(response.data.pages);
      }

    }).catch((error) => {
      //Handle Errors Coming out of this
    });

    return () => { isMounted = false; controller.abort(); }
    // eslint-disable-next-line
  }, [page]);

  return (
    <div className="col-lg-8">
      <div className="section-title">
        <h4 className="m-0 text-uppercase font-weight-bold">REGISTERED USERS</h4>
      </div>
      { listAdmins?.length>0 && 
        <div className='border border-white mb-3'>
          <div className="bg-white align-items-center mb-1 overflow-hidden p-3 border">
            <b>Administrators</b>
          </div>
          <div className="align-items-center mb-1 overflow-hidden p-3 row">
            { listAdmins?.map((item) => {
              return(
                <div key={item.id} className='col-lg-3 overflow-hidden align-items-left'>
                  <Link className="text-secondary" to={`/admin/users/${item.id}`}>
                    {item?.name ? item?.name : item?.email}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      }
      { listEditors?.length>0 && 
        <div className='border border-white mb-3'>
          <div className="bg-white align-items-center mb-1 overflow-hidden p-3 border">
            <b>Editors</b>
          </div>
          <div className="align-items-center mb-1 overflow-hidden p-3 row">
            { listEditors?.map((item) => {
              return(
                <div key={item.id} className='col-lg-3 overflow-hidden align-items-left'>
                  <Link className="text-secondary" to={`/admin/users/${item.id}`}>
                    {item?.name ? item?.name : item?.email}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      }
      {listAuthors?.length>0 && 
        <div className='border border-white mb-3'>
          <div className="bg-white align-items-center mb-1 overflow-hidden p-3 border">
            <b>Authors</b>
          </div>
          <div className="align-items-center mb-1 overflow-hidden p-3 row">
            { listAuthors?.map((item) => {
              return(
                <div key={item.id} className='col-lg-3 overflow-hidden align-items-left'>
                  <Link className="text-secondary" to={`/admin/users/${item.id}`}>
                    {item?.name ? item?.name : item?.email}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      }
      { listUsers?.length>0 && 
        <div className='border border-white'>
          <div className="bg-white align-items-center overflow-hidden px-3 pt-3">
            <b>Regular Users</b>
          </div>
          <div className="align-items-center pb-2  px-2">
            <div className='row bg-white px-2'>
              <div className='col-lg-5 p-2'>Username</div>
              <div className='col-lg-4 p-2'>Full Name</div>
              <div className='col-lg-3 p-2'>Phone Number</div>
            </div>
            { listUsers?.map((item,idx) => {
              return(
                <div key={item?.id} className={ `row border border-white px-2`} style={{cursor: 'pointer', backgroundColor:  idx%2===0  ? '' : '#F8FAFC' }} onClick={()=> navigate(`/admin/users/${item?.id}`)}>
                  <div className='col-lg-5 p-2'>{item?.email}</div>
                  <div className='col-lg-4 p-2'>{item?.name}</div>
                  <div className='col-lg-3 p-2'>
                    {item.telephone && [item?.telephone.slice(0, 4), ' ', item?.telephone.slice(4,7), ' ', item?.telephone.slice(7,10), ' ', item?.telephone.slice(10)].join('')}
                  </div>
                </div>  
              )
            })}
          </div>
          {totalPages>1 &&
            <Pagination totalPages={totalPages} setPage={setPage}/>
          }
        </div>
      }
    </div>
  )
}
