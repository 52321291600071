import {Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'

import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth'

const VIEW_TOPNEWS_URL = '/news/top'

function LatestNewsGrid({PF}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [topStory, setTopStory] = useState({});
    const [stories, setStories] = useState([]);
    const navigate = useNavigate();  

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_TOPNEWS_URL).then((response) => {
            if(isMounted){
                setTopStory(response.data.mainstory);
                setStories(response.data.stories);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <div className="section-title">
                <h4 className="m-0 text-uppercase font-weight-bold">Latest News</h4>
                {(authDecode && authDecode?.user?.role >=3) && 
                    <Link className="text-secondary font-weight-medium text-decoration-none" to="/news/add">
                        <i className="writeIcon fas fa-plus"></i> Add Story
                    </Link>
                }
            </div>
            <div className="row mx-0 mb-3">
                {topStory?.picture && 
                    <div className="col-lg-6 position-relative overflow-hidden px-0" style={{height: "500px"}} onClick={() => navigate(`/news/view/${topStory.id}`)}>
                        <img className="img-fluid h-100" src={PF + topStory.picture} style={{objectFit: "cover"}} alt="" />
                        <div className="overlay">
                            <div className="mb-2">
                                <Link className="text-white" to="/"><small>{new Date(topStory.createdAt).toDateString()}</small></Link>
                            </div>
                            <Link className="h6 m-0 text-white text-uppercase font-weight-semi-bold" to={`/news/view/${topStory.id}`}>{topStory.title}</Link>
                        </div>
                    </div>
                }
                <div className="col-lg-6 px-0">
                    <div className="row mx-0">
                        { stories.map((value)=>{
                            return (
                                <div key={value.id} className="col-lg-6 px-0 position-relative overflow-hidden" style={{height: "250px"}} onClick={() => navigate(`/news/view/${value.id}`)}>
                                    <img className="img-fluid w-100 h-100" src={PF + value.picture} style={{objectFit: 'cover'}} alt="" />
                                    <div className="overlay">
                                        <div className="mb-2">
                                            <Link className="text-white" to="/"><small>{new Date(value.createdAt).toDateString()}</small></Link>
                                        </div>
                                        <Link className="h6 m-0 text-white text-capitalize font-weight-semi-bold" to={`/news/view/${value.id}`}>{value.title}</Link>
                                    </div>
                                </div>
                            );
                        })}  
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LatestNewsGrid
