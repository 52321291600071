import {Routes, Route} from 'react-router-dom'

import ViewCompany from './adverts/ViewCompany'
import CompanyList from './adverts/CompanyList'
import AddCompany from './adverts/AddCompany'

export default function Adverts({PF}) {
  return (
    <Routes>
        <Route exact path='/view/:id' element={<ViewCompany PF={PF}/>} />
        <Route exact path='/add' element={<AddCompany PF={PF} />} />
        <Route exact path='/' element={<CompanyList PF={PF}/>} />
    </Routes>
  )
}
