import { Link, useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Select from 'react-select'

import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

const COMMITTEES_URL = '/admin/committees/obc/'

const CommsAddOBC = () => {
    let {id} = useParams();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [profiles, setProfiles] = useState([]);
    const [startYear, setStartYear] = useState('');
    const [endYear, setEndYear] = useState('');
    const [newPresident, setNewPresident] = useState('');
    const [newVP1, setNewVP1] = useState('');
    const [newVP2, setNewVP2] = useState('');
    const [newGS, setNewGS] = useState('');
    const [newTreasurer, setNewTreasurer] = useState('');
    const [newMobiliser, setNewMobiliser] = useState('');
    const [newAMob, setNewAMob] = useState('');
    const [newBJSL, setNewBJSL] = useState('');
    const [newBJSG, setNewBJSG] = useState('');
    const [newDR1, setNewDR1] = useState('');
    const [newDR2, setNewDR2] = useState('');
    const [newDR3, setNewDR3] = useState('');
    const [newDR4, setNewDR4] = useState('');
    const [newDR5, setNewDR5] = useState('');
    const [newDR6, setNewDR6] = useState('');
    // eslint-disable-next-line
    const [isClearable, setIsClearable] = useState(true);
    // eslint-disable-next-line
    const [isSearchable, setIsSearchable] = useState(true);
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(COMMITTEES_URL + id).then((response)=>{
            isMounted && setProfiles(response.data.profiles);
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, []);

    const handleSubmitExecutive = (e) => {
        e.preventDefault();

        const newExco = [
            {commId: 201, startYear: startYear, endYear: endYear, profileId: newPresident.value},
            {commId: 202, startYear: startYear, endYear: endYear, profileId: newVP1.value},
            {commId: 203, startYear: startYear, endYear: endYear, profileId: newVP2.value},
            {commId: 204, startYear: startYear, endYear: endYear, profileId: newGS.value},
            {commId: 205, startYear: startYear, endYear: endYear, profileId: newTreasurer.value},
            {commId: 206, startYear: startYear, endYear: endYear, profileId: newMobiliser.value},
            {commId: 207, startYear: startYear, endYear: endYear, profileId: newAMob.value},
            {commId: 208, startYear: startYear, endYear: endYear, profileId: newBJSL.value},
            {commId: 209, startYear: startYear, endYear: endYear, profileId: newBJSG.value},
            {commId: 210, startYear: startYear, endYear: endYear, profileId: newDR1.value},
            {commId: 211, startYear: startYear, endYear: endYear, profileId: newDR2.value},
            {commId: 212, startYear: startYear, endYear: endYear, profileId: newDR3.value},
            {commId: 213, startYear: startYear, endYear: endYear, profileId: newDR4.value},
            {commId: 214, startYear: startYear, endYear: endYear, profileId: newDR5.value},
            {commId: 215, startYear: startYear, endYear: endYear, profileId: newDR6.value},
        ];
        
        try {
            axiosPrivate.post(COMMITTEES_URL + 'add', newExco).then(()=>{
            alert('New Executive Added');
            navigate('/admin/committees')
            }).catch( function (error) { console.log(error.config); });   
        }catch (error) { console.log(error); }
    }

    return (
        <div className="col-lg-8">
            <div className="section-title">
                <h4 className="m-0 text-uppercase font-weight-bold">Add New Committee</h4>
            </div>
            <form onSubmit={handleSubmitExecutive}>
                <div className='mb-3 p-2'>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Tenure: </div>
                        <div className="col-lg-8">
                            <input type='text' placeholder='Start Year' className="p-1"onChange={(e)=>{setStartYear(e.target.value)}}/>
                            &nbsp;&nbsp;
                            <input type='text' placeholder='End Year' className="p-1"onChange={(e)=>{setEndYear(e.target.value)}}/>
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">President: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewPresident(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">1st Vice President: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewVP1(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">2nd Vice President: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewVP2(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">General Secretary: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewGS(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Treasurer: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewTreasurer(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Mobiliser: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewMobiliser(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Assistant Mobiliser: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewAMob(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Junior School Rep, Ladies: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewBJSL(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Junior School Rep, Gents: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewBJSG(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Decade Rep, 1906 - 1966: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewDR1(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Decade Rep, 1967 - 1976: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewDR2(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Decade Rep, 1977 - 1986: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewDR3(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Decade Rep, 1987 - 1996: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewDR4(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Decade Rep, 1997 - 2006: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewDR5(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Decade Rep, 2007 - : </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewDR6(e)}} />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-lg-4">&nbsp;</div>
                        <div className="col-lg-8">
                            <button className="btn btn-primary font-weight-semi-bold px-4" type="submit" disabled={!startYear || !endYear || !newPresident || !newVP1 || !newVP2 || !newGS || !newTreasurer || !newMobiliser || !newAMob || !newBJSL || !newBJSG || !newDR1 || !newDR2 || !newDR3 || !newDR4 || !newDR5 || !newDR6}>Submit</button>
                            &nbsp;&nbsp;
                            <Link className="btn btn-primary font-weight-semi-bold px-4" to='/admin/committees'>Cancel</Link>
                        </div>
                        <hr />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CommsAddOBC
