import {Routes, Route} from 'react-router-dom'

import ProductDetails from './products/ProductDetails'
import OrderDetails from './products/OrderDetails'
import ProductAdd from './products/ProductAdd'
import Products from './products/Products'
import Orders from './products/Orders'

export default function ShopProducts({PF}) {
  return (
    <Routes>
      <Route exact path='/products/:id' element={<ProductDetails PF={PF}/>} />
      <Route exact path='/products/add' element={<ProductAdd PF={PF}/>} />
      <Route exact path='/orders/:id' element={<OrderDetails PF={PF}/>} />
      <Route exact path='/products' element={<Products PF={PF}/>} />
      <Route exact path='/orders' element={<Orders PF={PF}/>} />
    </Routes>
  )
}
