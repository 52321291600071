import RightSideBar from '../../components/RightSideBar'
import StoryContent from './StoryContent'

function Story({PF}) {
    return (
        <div className="container-fluid pt-5 mb-3">
            <div className="container">
                <div className="row">
                    <StoryContent PF={PF} />
                    <RightSideBar PF={PF} />  
                </div>
            </div>
        </div>
    )
}

export default Story