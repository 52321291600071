import {Routes, Route} from 'react-router-dom';
import UniChapter from './about/UniChapter';
import AboutPage from './about/AboutPage';
import Trustees from './about/Trustees';
import Single from './about/Single';
import Exco from './about/Exco';

function About({PF}) {
  return (
    <div className="container-fluid pt-5">
      <div className="container">
        <Routes>
          <Route exact path='/' element={<AboutPage PF={PF} />} />
          <Route exact path='/:id' element={<Single PF={PF} />} />
          <Route exact path='/trustees' element={<Trustees PF={PF} />} />
          <Route exact path='/exco' element={<Exco PF={PF} />} />
          <Route exact path='/obuc' element={<UniChapter PF={PF} />} />
        </Routes>
      </div>
    </div>
  )
}


export default About