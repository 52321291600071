import RightSideBar from '../../components/RightSideBar'
import LatestNewsGrid from './LatestNewsGrid'
import StoriesContent from './StoriesContent'

function Stories({PF}) {
    return (
        <div className='container-fluid pt-5 mb-3'>
            <LatestNewsGrid PF={PF} />
            <div className="container">
                <div className='row'>
                    <StoriesContent PF={PF} />
                    <RightSideBar PF={PF} />
                </div>
            </div>
        </div>
    )
}

export default Stories