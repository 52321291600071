import {useState, useEffect} from 'react'
import { Link } from "react-router-dom"
import Slider from "react-slick"

import useAxiosPrivate from '../../hooks/useAxiosPrivate'

const VIEW_HERO_URL = '/home/hero'

function HomeSlider({PF}) {
    const axiosPrivate = useAxiosPrivate();
    const [gallery, setGallery] = useState('');
    const [images, setImages] = useState([]);
    const [news, setNews] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_HERO_URL).then((response) => {
            if(isMounted){
                setGallery(response.data.gallery)
                setImages(response.data.pictures);
                setNews(response.data.stories);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, []);

    var settings = { dots: false, infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1, arrows: true, autoplay: true };

    return (
        <div className="container-fluid mb-3">
            <div className="row">
                <div className="col-lg-7 px-0">
                    <div className="mx-0">
                        <Slider {...settings} className="position-relative">
                            {images.length>0 && images.map((image) => {
                                return(
                                    <div className="owl-carousel tranding-carousel position-relative overflow-hidden px-0" key={image.id} style={{height: '450px'}}>
                                        <Link to={`/gallery/view/${gallery.id}`}><img className="img-fluid w-100" src={PF + image.picture} style={{height: '442px', objectFit: 'cover'}} alt=''/></Link>
                                    </div>                                    
                                )
                            })}
                        </Slider>
                    </div>
                </div>
                <div className="col-lg-5 px-0">
                    <div className="row mx-0">
                        {news.length>0 && news.map((story) =>{
                            return(
                                <div className="col-md-6 px-0" key={story.id}>
                                    <div className="position-relative overflow-hidden" style={{height: '221px'}}>
                                        <img className="img-fluid w-100 h-100" src={PF + story.picture} style={{objectFit: 'cover'}} alt=''/>
                                        <div className="overlay">
                                            <div className="mb-2">
                                                <small className="text-white">{new Date(story.createdAt).toDateString()}</small>
                                            </div>
                                            <Link className="h6 m-0 text-white text-uppercase font-weight-semi-bold" to={`/news/view/${story.id}`}>{story.title}</Link>
                                        </div>
                                    </div>
                                </div>    
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default HomeSlider;