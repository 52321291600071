import RightSideBar from '../../components/RightSideBar'
import ShelvesContent from './ShelvesContent'

function Shelves({PF}) {

  return (
    <div className='row'>
      <ShelvesContent PF={PF} />
      <RightSideBar PF={PF} />
    </div>
  )
}

export default Shelves