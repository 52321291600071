import { Routes, Route } from 'react-router-dom'

import RequireAuth from '../components/RequireAuth'
import ViewProfiles from './profiles/Profiles'
import AddProfile from './profiles/AddProfile'
import ViewProfile from './profiles/Profile'

function Profiles({PF}) {
  return (
    <Routes>
      <Route exact path='/' element={<ViewProfiles PF={PF} />} />
      <Route exact path='/view/:id' element={<ViewProfile PF={PF} />} />
      <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
        <Route exact path='/add' element={<AddProfile PF={PF}/>} />
      </Route>
    </Routes>
  )
}

export default Profiles