/* eslint-disable jsx-a11y/anchor-has-content */
import {Link, useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import Select from 'react-select'
import { useState } from 'react'

import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import HouseList from '../../components/HouseList'
import YearList from '../../components/YearList'
import useAuth from '../../hooks/useAuth'

//Editor
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const ADD_PROFILE_URL = '/alumni/add/'

export default function AddAlum({PF}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [entryYear, setEntryYear] = useState('');
    const [exitYear, setExitYear] = useState('');
    const [classOf, setClassOf] = useState('');
    const [detail, setDetail] = useState('');
    const [house, setHouse] = useState('');
    const [name, setName] = useState('');
    const navigate = useNavigate();

    const onDetail = (value) => {
        setDetail(value);
    } 

    const addProfile = async(e) => {
        e.preventDefault();
        const profile = {name, house, entryYear, exitYear, classOf, profile: detail, userId: authDecode.user.id};
        console.log(profile)
        try {
            axiosPrivate.post(ADD_PROFILE_URL, profile).then(()=>{
                alert('Alumni Record Added');
                navigate('/alum');
            });   
        }catch (error) {
            console.log(error);  
        }
    }

    return (
      <div className="col-lg-8 mb-3">    
        <div className='main-form'>
          <form onSubmit={addProfile} data-toggle="validator">
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-group section-title">
                        <h5>Name: </h5>&nbsp;&nbsp;
                        <input type="text" value={name} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setName(e.target.value)} />
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group">
                        <br /><b>House: </b>
                        <Select defaultInputValue={house} options={HouseList} onChange={(e)=>setHouse(e.value)} />
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group">
                        <br /><b>Class of: </b>
                        <Select defaultInputValue={classOf} options={YearList} onChange={(e)=>setClassOf(e.value)} />
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group">
                        <br /><b>Year of Entry: </b>
                        <Select defaultInputValue={entryYear} options={YearList} onChange={(e)=>setEntryYear(e.value)} />
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group">
                        <br /><b>Year of Exit: </b>
                        <Select defaultInputValue={exitYear} options={YearList} onChange={(e)=>setExitYear(e.value)} />
                    </div>
                </div>
                <div className="col-lg-12 bg-white border p-3">
                    <br /><h5>Profile: </h5>
                    <div className='form-group'>
                        <ReactQuill theme="snow" rows="10" value={detail} onChange={onDetail} />
                    </div>

                </div>
            </div>
            <br />
            <p className="form-message"></p>
            <button type="submit" className="btn btn-primary font-weight-semi-bold px-4" disabled={!name || !detail}>Send</button>
            &nbsp;&nbsp;&nbsp;
            <Link className="btn btn-primary font-weight-semi-bold px-4" to='/alum'>Cancel</Link> 
          </form>
        </div>
      </div>
    )
}
