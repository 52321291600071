import {Routes, Route} from 'react-router-dom'

import RightSideBar from '../components/RightSideBar'
import ViewPosts from './user/ViewPosts'
import ViewUser from './user/ViewUser'
import ViewAlum from './user/ViewAlum'

function User({PF}) {
  return (
    <div className="container-fluid pt-5 mb-3">
      <div className="container">
        <div className='row'>
          <Routes>
            <Route exact path='/posts/:id' element={<ViewPosts PF={PF} />} />
            <Route exact path='/alum/:id' element={<ViewAlum PF={PF}/>} />
            <Route exact path='/view/:id' element={<ViewUser PF={PF} />} />
          </Routes>
          <RightSideBar PF={PF} />
        </div>
      </div>
    </div>
  )
}

export default User