import { Routes, Route } from 'react-router-dom'

import RequireAuth from '../components/RequireAuth'
import AddStory from './news/AddStory'
import Stories from './news/Stories'
import Story from './news/Story'

function News({PF}) {
  return (
    <Routes>
      <Route exact path='/' element={<Stories PF={PF} />} />
      <Route exact path='/view/:id' element={<Story PF={PF} />} />
      <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
        <Route exact path='/add' element={<AddStory PF={PF} />} />
      </Route>
    </Routes>
  )
}

export default News