import {Routes, Route} from 'react-router-dom'

import UserDetails from './users/UserDetails'
import UserLists from './users/UserLists'

export default function Users({PF}) {
  return (
    <Routes>
      <Route exact path='/:id' element={<UserDetails PF={PF}/>} />
      <Route exact path='/' element={<UserLists PF={PF}/>} />
    </Routes>
  )
}
