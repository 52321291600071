import RightSideBar from "../../components/RightSideBar";
import ProfileContent from './ProfileContent';

function Profile({PF}) {
    return (
        <div className="container-fluid pt-5 mb-3">
            <div className="container">
                <div className='row'>
                    <ProfileContent PF={PF} />
                    <RightSideBar PF={PF} />  
                </div>
            </div>
        </div>
    )
}

export default Profile