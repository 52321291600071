import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { Link, useNavigate } from "react-router-dom"
import ReactQuill from "react-quill"
import jwtDecode from 'jwt-decode'
import { useState } from "react"

import useAuth from '../../hooks/useAuth'
import "react-quill/dist/quill.snow.css"
import "../../css/TextEditor.css"

const ADD_PROFILE_URL = '/profiles/add'
const UPLOAD_FILE_URL = '/upsingle'

function NewProfileForm() {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [profileTitle, setProfileTitle] = useState('');
    const [profileText, setProfileText] = useState('');
    const [file, setFile] = useState(null);
    let navigate = useNavigate();

    const onProfileText = (value) => {
        setProfileText(value);
    } 
    
    const profileSubmit = async (e) => {
        e.preventDefault();
        const newProfile = { profileTitle, profileText, userId: authDecode.user.id, };

        if(file){
        const formData = new FormData();
        const filename = Date.now() + file.name;
        
        formData.append("name", filename);
        formData.append("file", file);
        newProfile.pictures = filename;

        try{
            await axiosPrivate.post(UPLOAD_FILE_URL, formData);
        }catch(err){
            console.log(err);
        }
        }

        try {
            await axiosPrivate.post(ADD_PROFILE_URL, newProfile).then((response)=>{
                console.log(response);
                navigate('/profiles');
            });
        }catch (error) {
            console.log(error);  
        }
    };

    return (
        <div className="col-lg-8">
            <div className="section-title mb-3">
                <h4 className="m-0 text-uppercase font-weight-bold">Write New Profile</h4>
            </div>
            {file && (
                <img className="img-fluid" src={URL.createObjectURL(file)} alt="" />
            )}
            <form onSubmit={profileSubmit}>
                <div className="form-group">
                    <label htmlFor="fileInput">
                        <i className="writeIcon fas fa-plus"></i>
                    </label> Add Profile Picture
                    <input 
                        type="file" 
                        id="fileInput" 
                        style={{display: "none"}} 
                        onChange={(e)=>setFile(e.target.files[0])} 
                    />
                </div>
                <div className="form-group">
                    <input 
                        type="text" 
                        placeholder="Alumnus Name" 
                        className="form-control p-4" required="required" 
                        autoFocus={true}
                        onChange={(e)=>setProfileTitle(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <ReactQuill theme="snow" rows="10" value={profileText} onChange={onProfileText} placeholder={"Profile Details here ...."} />
                </div>
                <button className="btn btn-primary font-weight-semi-bold px-4" disabled={!file || !profileTitle || !profileText} type="submit">Submit</button>
                &nbsp;&nbsp;&nbsp;  
                <Link className="btn btn-primary font-weight-semi-bold px-4" to='/profiles'>Cancel</Link>  
            </form>
        </div>            
    );
}

export default NewProfileForm