import {Routes, Route} from 'react-router-dom';

import LeftSideBar from '../components/LeftSideBar'
import Committees from './admin/Committees'
import ShopProducts from './admin/Products'
import Dashboard from './admin/Dashboard'
import Adverts from './admin/Adverts'
import Alerts from './admin/Alerts'
import Users from './admin/Users'

function Admin({PF}) {
  return (
    <div className="container-fluid pt-5 mb-3">
      <div className="container">
        <div className='row'>
          <LeftSideBar PF={PF} />
          <Routes>
            <Route exact path='/committees/*' element={<Committees PF={PF}/>} />
            <Route exact path='/shop/*' element={<ShopProducts PF={PF}/>} />
            <Route exact path='/adverts/*' element={<Adverts PF={PF}/>} />
            <Route exact path='/users/*' element={<Users PF={PF}/>} />
            <Route exact path='/alerts' element={<Alerts PF={PF}/>} />
            <Route exact path='/' element={<Dashboard PF={PF}/>} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default Admin