import { useParams, useNavigate} from "react-router-dom"
import { useState, useEffect } from "react"

import useAxiosPrivate from "../../../hooks/useAxiosPrivate"

const ORDER_URL = '/admin/orders/'

const OrderDetails = ({PF}) => {
    const axiosPrivate = useAxiosPrivate();
    const [user, setUser] = useState({});
    const [order, setOrder] = useState({});
    const [orderItems, setOrderItems] = useState([])
    let {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        axiosPrivate.get(ORDER_URL + id).then((response) => {
        if(isMounted){
          setUser(response.data.user);
          setOrder(response.data.order);
          setOrderItems(response.data.orderItems);
        }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    const handleDispatch = async(e) => {
        e.preventDefault();      
        try {
          axiosPrivate.put(ORDER_URL + order.orderCode).then(()=>{
            alert('Order set as Dispatched');
            navigate(`/admin/orders`);
          }).catch( function (error) { console.log(error.config); });   
        }catch (error) { console.log(error); }
    }

    const handleCancelOrder = async(e) => {
      e.preventDefault();
      try {
        axiosPrivate.delete(ORDER_URL + order.orderCode).then(()=>{
          alert('Order Deleted');
          navigate(`/admin`);
        }).catch( function (error) { console.log(error.config); });   
      }catch (error) { console.log(error); }
    }

  return (
    <div className="col-lg-8">
      <div className="section-title">
        <h4 className="text-secondary m-0"><b>ORDER #</b> - {id} - {order?.amount}/-</h4>
      </div>
      <div className=" bg-white align-items-center mb-1 overflow-hidden p-3 border">
        By: <b>{user?.name}</b> [ {user?.email}, {user?.telephone } ]
      </div>
      <div className="row">
        <div className="col-lg-8 px-2">
          { orderItems.map((item) => (
            <div className="row mb-2 overflow-hidden" key={item.id} style={{height: '100px'}}>
                <div className="col-2">
                <img src={PF + item.image} className="img-fluid h-100" style={{objectFit: 'cover'}} alt="" />
                </div>
                <div className="col-10 border">
                <div className="details">
                    {item.title}
                    <div className="price"><small>{item.quantity} x {item.price.toLocaleString('en')}/-</small></div>
                </div>
                </div>
            </div>
          ))}
          <div className="row mb-2 overflow-hidden" style={{height: '100px'}}>
            <div className="col-2 item">
              <img src={PF + 'Delivery.jpg'} className="img-fluid w-100 h-100" style={{objectFit: 'cover'}} alt="" />
            </div>
            <div className="col-10 item border">
              <div className="details">
                Delivery Charges
                <div className="price"><small>5,000/-</small></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
            <div className="d-flex align-items-center bg-white mb-1 overflow-hidden p-3 border">
                <span className="h6 m-0 text-secondary text-capitalize font-weight-bold">Payment</span>
            </div>
            <div className="align-items-center mb-1 overflow-hidden p-3 border">
                { order?.mmCode === null ? (<>
                  <b>Status: Pending</b>
                  <br /><br />
                  <button className="btn btn-primary font-weight-semi-bold px-1" type="submit" onClick={(e) => {handleCancelOrder(e)}}>Cancel Order</button>
              </>) : (<>
                Status: <b>Paid</b><br /><br />
                Vendor: {order.vendor}<br /><br />
                Code: {order.mmCode}
              </>) }
            </div>
            { order?.mmCode===null ? <></> : order?.dispatched>0 ? 
              <>
                <div className="d-flex align-items-center bg-white mb-1 overflow-hidden p-3 border">
                  <span className="h6 m-0 text-secondary text-capitalize font-weight-bold">Delivery</span>
                </div>
                <div className="align-items-center mb-1 overflow-hidden p-3 border">
                  <b>Status: </b>Dispatched
                  <br /><br />
                  <b>Date: </b>{new Date(order.updatedAt).toDateString()}
                </div>
              </>
              : 
              <>
                <div className="d-flex align-items-center bg-white mb-1 overflow-hidden p-3 border">
                  <span className="h6 m-0 text-secondary text-capitalize font-weight-bold">Dispatch</span>
                </div>
                <div className="align-items-center mb-1 overflow-hidden p-3 border">
                  Status: <b>Pending</b>
                  <br /><br />
                  <button className="btn btn-primary font-weight-semi-bold px-1" type="submit" onClick={(e) => {handleDispatch(e)}}>Dispatch</button>
                  &nbsp;&nbsp;
                  <button className="btn btn-primary font-weight-semi-bold px-1" type="submit" onClick={(e) => {handleCancelOrder(e)}}>Cancel Order</button>
                </div>
              </>
            }           
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
