import {Link, useNavigate, useLocation} from "react-router-dom"
import {useState, useRef, useEffect} from "react"
import { useSelector } from "react-redux"
import jwtDecode from 'jwt-decode'

import Cart from '../modules/shop/components/Cart'
import useLogout from '../hooks/useLogout'
import useAuth from '../hooks/useAuth'

function Navbar({PF}) {
  const { auth } = useAuth();
  const location = useLocation();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const from = location.state?.from?.pathname || `/`;
  const logOut = useLogout();
  const products = useSelector((state) => state.cart.products);
  const [open, setOpen] = useState(false);
  const cartRef = useRef(null);

  const navigate = useNavigate();

  const signOut = async () => {  
    await logOut(); 
    navigate(from, { replace: true });
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleOutsideClick = (e) => {
    if (cartRef.current && !cartRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  let activeLocation = location?.pathname.split('/').pop();
    
  let activeNav = '';
  
  if (activeLocation === '1') {
      activeNav = 'about';
  } else if(activeLocation === '2') {
      activeNav = 'about';
  } else if(activeLocation === '3') {
      activeNav = 'about';
  } else if(activeLocation === 'trustees') {
      activeNav = 'about';
  } else if(activeLocation === 'exco') {
      activeNav = 'about';
  } else if(activeLocation === 'obuc') {
      activeNav = 'about';
  } else if(activeLocation === '4'){
      activeNav = 'membership';
  } else if(activeLocation === '5'){
    activeNav = 'membership';
} else if(activeLocation === 'news'){
      activeNav = 'news';
  } else if(activeLocation === 'events'){
      activeNav = 'news';
  } else if(activeLocation === 'articles'){
      activeNav = 'news';
  } else if(activeLocation === 'gallery'){
  activeNav = 'media';
  } else if(activeLocation === 'library'){
    activeNav = 'media';
  } else if(activeLocation === 'profiles'){
    activeNav = 'alumni';
  } else if(activeLocation === 'alum'){
    activeNav = 'alumni';
  } else if(activeLocation === 'shop'){
    activeNav = 'shop';
  }

  return (
    <>
    { /** -- Topbar Start  -- */ }
    <div className="container-fluid d-none d-lg-block">
      <div className="row align-items-center bg-dark px-lg-5">
        <div className="col-lg-9">
          <nav className="navbar navbar-expand-sm bg-dark p-0">
            <ul className="navbar-nav ml-n2">
              <li className="nav-item border-right border-secondary">
                <Link className="nav-link text-body small" to="#date">{ new Date().toDateString()}</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="col-lg-3">
          <nav className="navbar navbar-expand-sm bg-dark p-0">
            <ul className="navbar-nav ml-auto mr-n2">
              {products.length>0 && (
                <li className="nav-item">
                  <Link className="nav-link text-body font-weight-bold" to="/shop/cart"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                  <span className="superscript" style={{cursor: 'pointer'}} onClick={()=>{setOpen(!open)}}>{products.length}</span> 
                </li>
              )}
              <li className="nav-item">
                <Link className="nav-link text-body font-weight-bold" to="https://www.twitter.com/budonian"><i className="fab fa-twitter"></i></Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-body font-weight-bold" to="https://www.facebook.com/The-Old-Budonians-Club-1533385500286920"><i className="fab fa-facebook-f"></i></Link>
              </li>
              <li className="nav-item border-right border-secondary">
                <Link className="nav-link text-body font-weight-bold" to="https://www.linkedin.com/in/administrator-old-budonians-club-295aa8261"><i className="fab fa-linkedin-in"></i></Link>
              </li>
              {authDecode ? (
                <>
                  {authDecode?.user?.role >= 4 && <li className="medium"><Link className="nav-link text-white" to={`/admin`} ><i className="fa fa-cogs" aria-hidden="true" /></Link></li>}
                  <li className="nav-toggler-icon"><Link className="nav-link text-white" to={`/user/view/${authDecode?.user?.id}`} ><i className='fa fa-user' aria-hidden="true"/></Link></li>
                  <li className="medium"><Link className="nav-link text-white" to='/' onClick={signOut}><i className='fa fa-sign-out-alt' aria-hidden="true" /></Link></li>
                </>                
              ):(
                <li className="nav-item">
                  <Link className="nav-link text-body font-weight-bold" to="/login"><i className="fas fa-sign-in-alt"></i></Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
      <div className="row align-items-center bg-white py-0 px-lg-5">
        <div className="col-lg-2">
          <Link to='/' className="navbar-logo">
            <img className="img-fluid mr-2" src='img/logo.jpg' style={{width: '120px', height: '120px', borderRadius: '50%'}} alt="" />
          </Link>
        </div>
        <div className="col-lg-8 text-left text-lg-left">
          <Link to='/' className="navbar-brand p-0 d-none d-lg-block"> 
            <h1 className="m-0 display-4 text-uppercase text-primary">Old Budonians'<span className="text-secondary font-weight-normal"> Club</span></h1>
          </Link>
        </div>
        {/* <div className="col-lg-2 p-0 d-none d-lg-block">
            <Link to="/donate" className="main-btn">Donate</Link>
        </div> */}
      </div>
    </div>
    { /** -- Topbar End  -- */ }

    { /** -- Navbar Start  -- */ }
    <div className="container-fluid p-0">
      <nav className="navbar navbar-expand-lg bg-red navbar-dark py-1 py-lg-0 px-lg-5" ref={cartRef}>
        <Link to='/' className="navbar-brand d-block d-lg-none"><img className="img-fluid mr-2" src='img/logo.jpg' style={{width: '70px', height: '70px', borderRadius: '50%'}} alt="" /></Link>
        <Link to="/donate" className="main-btn d-block d-lg-none">Donate</Link>
        <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-between px-0 px-lg-3" id="navbarCollapse">
          <div className="navbar-nav mr-auto py-0">
            <div className="nav-item dropdown">
              <Link to="#about" className={activeNav === 'about' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} data-toggle="dropdown">About</Link>
              <div className="dropdown-menu rounded-0 m-0 p-0">
                <Link to="/about/1" className={activeLocation === '1' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>History</Link>
                <Link to="/about/2" className={activeLocation === '2' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>Club Structure</Link>
                <Link to="/about/trustees" className={activeLocation === 'trustees' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>Trustees</Link>
                <Link to="/about/exco" className={activeLocation === 'exco' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>Executive</Link>
                <Link to="/about/obuc" className={activeLocation === 'obuc' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>University Chapter</Link>
              </div>
            </div>
            <div className="nav-item dropdown">
              <Link to="#membership" className={activeNav === 'membership' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} data-toggle="dropdown">Membership</Link>
              <div className="dropdown-menu rounded-0 m-0 p-0">
                <Link to="/about/4" className={activeLocation === '4' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>Categories</Link>
                <Link to="/about/5" className={activeLocation === '5' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>Payment</Link>
              </div>
            </div>
            <div className="nav-item dropdown">
              <Link to="#news" className={activeNav === 'news' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} data-toggle="dropdown">News & Events</Link>
              <div className="dropdown-menu rounded-0 m-0 p-0">
                <Link to="/news" className={activeLocation === 'news' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>News</Link>
                <Link to="/events" className={activeLocation === 'events' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>Events</Link>
                <Link to="/articles" className={activeLocation === 'articles' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>Blog Posts</Link>
              </div>
            </div>
            <div className="nav-item dropdown">
              <Link to="#media" className={activeNav === 'media' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} data-toggle="dropdown">Media</Link>
              <div className="dropdown-menu rounded-0 m-0 p-0">
                <Link to="/gallery" className={activeLocation === 'gallery' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>Photo Gallery</Link>
                <Link to="/library" className={activeLocation === 'library' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>Documents</Link>
              </div>
            </div>
            <div className="nav-item dropdown">
              <Link to="#alumni" className={activeNav === 'alumni' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} data-toggle="dropdown">Alumni</Link>
              <div className="dropdown-menu rounded-0 m-0 p-0">
                <Link to="/profiles" className={activeLocation === 'profiles' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>Alumni Profiles</Link>
                <Link to="/alum" className={activeLocation === 'alum' ? 'active dropdown-item p-2' : 'dropdown-item p-2'}>Alumni Database</Link>
              </div>
            </div>
            <Link to="/shop" className={activeNav === 'shop' ? 'nav-link active' : 'nav-link'}>Shop</Link>
            {authDecode ? <Link className="nav-link d-lg-none" to='#logout' onClick={signOut}>Logout</Link> : <Link className='nav-link d-lg-none' to="/login">Login</Link> }
          </div>
          <div className="input-group ml-auto d-none d-lg-flex" style={{width: '100%', maxWidth: '300px'}}>
            <input type="text" className="form-control border-0" placeholder="Keyword" />
            <div className="input-group-append">
              <button className="input-group-text bg-primary text-dark border-0 px-3"><i className="fa fa-search"></i></button>
            </div>
          </div>
        </div>
        {products.length>0 && open && authDecode && <Cart PF={PF}/>}
      </nav>
      <nav className="navbar navbar-expand-lg bg-red py-0 justify-content-between d-lg-none">   
        {authDecode && (
          <>
            { products.length>0 && (
              <div className="nav-item">
                <Link className="nav-link text-body font-weight-medium" to="/shop/cart"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                <span className="superscript" style={{cursor: 'pointer'}} onClick={()=>{setOpen(!open)}}>{products.length}</span> 
              </div>
            )}
            <div className="nav-item">
              {authDecode?.user?.role >= 4 && <Link className="nav-link text-body font-weight-medium" to={`/admin`} ><i className="fa fa-cogs" aria-hidden="true" /></Link>}
            </div>

            <div className="nav-item">
              <Link className="nav-link text-body font-weight-medium" to={`/user/view/${authDecode?.user?.id}`} ><i className='fa fa-user' aria-hidden="true"/></Link>
            </div>
          </>
        )}
      </nav>
    </div>
    { /** -- Navbar End -- **/ }
    </>
  );
}

export default Navbar;