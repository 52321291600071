import { Link, useParams, useNavigate} from 'react-router-dom'
import {FileAddOutlined} from '@ant-design/icons'
import { useState, useEffect } from 'react'

import useAxiosPrivate from '../../hooks/useAxiosPrivate'

const VIEW_PICTURES_URL = '/galleries/viewpic/'
const EDIT_PICTURE_URL = '/galleries/editpic/'
const UPLOAD_FILE_URL = '/upsingle'

function EditPicture({PF}) {
  const axiosPrivate = useAxiosPrivate();
  const [picture, setPicture] = useState('');  
  const [caption, setCaption] = useState('');  
  const [gallery, setGallery] = useState('');  
  const [file, setFile] = useState(false);
  const navigate = useNavigate();
  let {id} = useParams();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_PICTURES_URL + id).then((response)=>{
      if(isMounted){
        setPicture(response.data);
        setCaption(response.data.caption);
        setPicture(response.data.picture);
        setGallery(response.data.galleryId);
      }
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [id]);

  const updatePicture = async(e) => {
      e.preventDefault();
      const updatedPicture = {caption};

      if(file){
          const formData = new FormData();
          const filename = Date.now() + file.name;
          
          formData.append("name", filename);
          formData.append("file", file);
          updatedPicture.picture = filename;
    
          try{
            await axiosPrivate.post(UPLOAD_FILE_URL, formData);
          }catch(err){
            console.log(err);
          }
      }

    try {
      axiosPrivate.put(EDIT_PICTURE_URL + id, updatedPicture).then(()=>{
        alert('Gallery Picture Edited');
        navigate(`/gallery/view/${gallery}`);
      }).catch();   
    }catch (error) {
      console.log(error);  
    }
  }

  return (
    <div className='eventSelected'>
      <div className='eventImage'>
          <label htmlFor='fileInput'><FileAddOutlined /></label>
          <input type='file' id='fileInput' style={{display: 'none'}} onChange={(e)=>setFile(e.target.files[0])} />
          { file ? (
              <img className="eventImage" src={URL.createObjectURL(file)} alt="" />
          ): (
              <img src={PF + picture} alt="" className="eventImage" />
          )}
      </div>
      <div className='eventDetails'>
          <textarea value={caption} type='text' className='writeInput writeText' onChange={(e)=>setCaption(e.target.value)} />
          <button className="writeSubmit" type="submit" onClick={updatePicture}>Submit</button>
          &nbsp;&nbsp;&nbsp;  
          <Link className="btn btn-primary font-weight-semi-bold px-4" to={`/gallery/view/${gallery}`}>Cancel</Link>          
      </div>
    </div>
  )
}

export default EditPicture
