import { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'

import useAxiosPrivate from '../../hooks/useAxiosPrivate'

const VIEW_OBUC_URL = '/committees/obuc'

function UniChapterContent({PF}) {
    const axiosPrivate = useAxiosPrivate();
    const [obuc, setObuc] = useState({});
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_OBUC_URL).then((response)=>{
            if(isMounted){
                setObuc(response.data);     
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="col-lg-12 mb-3">
            <h2 className="mb-3 text-secondary text-capitalize font-weight-bold">Old Budonians University Chapter</h2>
            <p className="mt-3" style={{whiteSpace: "pre-line"}}>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint ipsa velit nulla vitae, alias aut illum. Corporis, libero officia sapiente repellendus aspernatur nihil ad. Sapiente mollitia voluptate placeat voluptatem quasi.
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint ipsa velit nulla vitae, alias aut illum. Corporis, libero officia sapiente repellendus aspernatur nihil ad. Sapiente mollitia voluptate placeat voluptatem quasi.
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint ipsa velit nulla vitae, alias aut illum. Corporis, libero officia sapiente repellendus aspernatur nihil ad. Sapiente mollitia voluptate placeat voluptatem quasi.
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint ipsa velit nulla vitae, alias aut illum. Corporis, libero officia sapiente repellendus aspernatur nihil ad. Sapiente mollitia voluptate placeat voluptatem quasi.
            </p>
            <div className="row">
                {obuc?.length>0 && obuc.map((item) =>{
                    return(
                        <div key={item.profileId} className="col-lg-4 mb-5">
                            <div className="section-title mb-1">
                                <h5 className="m-0 text-uppercase font-weight-bold">{item.comm.description}</h5>
                            </div>
                            <Link to={`/profiles/view/${item.profileId}`}>
                                <div className="position-relative overflow-hidden pr-2 mb-2" style={{height: "300px"}} >
                                    <img className="img-fluid h-100" src={PF + item.profile.pictures} style={{objectFit: "cover"}} alt="" />
                                </div>
                                <div className="h6 m-0 text-uppercase font-weight-semi-bold">
                                    {item.profile.profileTitle}
                                </div>
                            </Link>  
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default UniChapterContent