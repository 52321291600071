import { Link } from 'react-router-dom'

function HomeMerch({PF, merch}) {    
    return (
        <>
            { merch.length>0 &&
                <>
                    <div className="col-12">
                        <div className="section-title">
                            <h4 className="m-0 text-uppercase font-weight-bold">New Arrivals</h4>
                            <Link className="text-secondary font-weight-medium text-decoration-none" to="/shop">View All</Link>
                        </div>
                    </div>
                    {merch.map((item) => {
                        let image = item.images.split(',');
                        return(
                            <div key={item.id} className="col-lg-4">
                                <div className="w-100 position-relative overflow-hidden d-flex bg-white mb-3 border border-left-0" style={{height: '110px', width: '110px',  objectFit: 'cover'}} >
                                    <img className="img-fluid w-100" src={PF + image[1]} alt=""style={{objectFit: 'cover'}}/>
                                    <div className="w-100 h-100 px-3 d-flex flex-column justify-content-center">
                                        <Link className="h6 m-0 text-secondary text-uppercase font-weight-bold" to={`/shop/product/${item.id}`}>{item.title}</Link>
                                        <p>{item.price.toLocaleString('en')}/-</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}                  
                </>
            }
        </>
    )
}

export default HomeMerch
