import RightSideBar from '../../components/RightSideBar';
import ArticleContent from './ArticleContent';

function Article({PF}) {
    return (
        <div className="container-fluid pt-5 mb-3">
            <div className="container">
                <div className="row">
                    <ArticleContent PF={PF} />
                    <RightSideBar PF={PF} />  
                </div>
            </div>
        </div>
    )
}

export default Article