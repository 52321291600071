import RightSideBar from '../../components/RightSideBar';
import ShelfContent from './ShelfContent';

function Shelf({PF}) {
    return (
        <div className="row">
            <ShelfContent PF={PF} />
            <RightSideBar PF={PF} />  
        </div>
    )
}

export default Shelf