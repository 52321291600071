import {Link, useParams, useNavigate} from 'react-router-dom'
import { useRef, useEffect, useState} from 'react'

import { axiosPrivate } from '../../../assets/axios'

const USER_URL = '/admin/users/'

function UserDetails({PF}) {

  const errRef = useRef();

  const [userProfile, setUserProfile] = useState({});  
  const [userRole, setUserRole] = useState();
  const [userSuspend, setUserSuspend] = useState();  

  //Editing User
  const [changePwd, setChangePwd] = useState(false);

  //User submitted content
  const [userStories, setUserStories] = useState([]);
  const [userArticles, setUserArticles] = useState([]);
  const [userProfiles, setUserProfiles] = useState([]);
  const [userGalleries, setUserGalleries] = useState([]);

  const [pwd, setPwd] = useState('');
  const [pwdFocus, setPwdFocus] = useState(false);
  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();

  let {id} = useParams();
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(USER_URL + id).then((response)=>{
      if(isMounted){
        setUserProfile(response.data.foundUser); 
        setUserRole(response.data.foundUser.role);
        setUserSuspend(response.data.foundUser.suspend); 
        setUserStories(response.data.userStories); 
        setUserArticles(response.data.userArticles);
        setUserProfiles(response.data.userProfiles); 
        setUserGalleries(response.data.userGalleries); 
      }
    }).catch((error) => {
      //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd])

  useEffect(() => {
      setErrMsg('');
  }, [pwd, matchPwd])

  const handleUpdateUser = async(e) => {

    e.preventDefault();
    const updatedUser = {role: userRole, suspend: userSuspend  };
      
    try {
      axiosPrivate.put(USER_URL + userProfile.id, updatedUser).then(()=>{
        alert('Profile Edited Successfully');
        navigate(`/admin/users/${userProfile.id}`);
      }).catch( function (error) { console.log(error.config); });   
    }catch (error) { console.log(error); }
  }

  const handleChangePwd = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v2 = pwd;
    if (!v2) {
      setErrMsg("Invalid Entry");
      return;
    }

    const updatedUserPwd = {pwd}
    try {
      await axiosPrivate.put(USER_URL + userProfile.id, updatedUserPwd);
      //clear state and controlled inputs
      //need value attrib on inputs for this
      setPwd('');
      setMatchPwd('');
      setChangePwd(false);
      alert('Password Updated Successfully');
      navigate(`/admin/users/${userProfile.id}`);
    } catch (err) {
      errRef.current.focus();
    }
  }

  return (
    <div className="col-lg-8 mb-3">
      <div>
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
        <div className="section-title">
          <h4 className="m-0 font-weight-bold">{userProfile?.name ? userProfile.name : userProfile?.email }</h4>
        </div>
      </div>
      <form>
      <div className="row">
        <div className="col-lg-8 row">
          <div className='col-2 p-2'>Role: </div>
          <div className='col-10'>
            <select name="role" className='form-control' value={userRole} onChange={(e) => setUserRole(e.target.value)}>
              <option value="1">Regular</option>
              <option value="2">Author</option>
              <option value="3">Editor</option>
              <option value="4">Administrator</option>
            </select> 
          </div>
        </div>
        <div className="col-lg-2 row">
          <div className='col-8 p-2'>
            Suspend: &nbsp;&nbsp;
          </div>
          <div className='col-4'>
            <input className='form-control p-2' type="checkbox" id="suspend" name="suspend" checked={userSuspend} onChange={()=>setUserSuspend(!userSuspend)} /> 
          </div>
        </div>
        <div className="col-lg-2">
          <button className="btn btn-primary font-weight-semi-bold px-4" style={{height: '35px'}} type="submit" onClick={(e)=>{handleUpdateUser(e)}} >Update</button>
        </div>
      </div>
      </form>
      <div className="row mx-0 mb-3">
        <div className="col-lg-5 position-relative overflow-hidden px-0">
          { userProfile?.picture && <img className="img-fluid w-100" src={PF + userProfile.picture} style={{objectFit: "cover"}} alt={userProfile?.name ? userProfile.name : userProfile?.email} />}
        </div>
        <div className="col-lg-7 position-relative overflow-hidden px-0 p-2">
          <b>Phone:</b> {userProfile.telephone && [userProfile.telephone.slice(0, 4), ' ', userProfile.telephone.slice(4,7), ' ', userProfile.telephone.slice(7,10), ' ', userProfile.telephone.slice(10)].join('')}<br/><br/>
          <b>Twitter:</b> {userProfile.twitter}<br/><br/>
          <b>LinkedIn:</b> {userProfile.linkedIn}<br/><br/>
          <b>Instagram:</b> {userProfile.instagram}<br/><br/>
          <b>Last Login:</b> {new Date(userProfile.updatedAt).toDateString()}
          <hr className='mb-2'/>
          <div className="bg-white align-items-center mb-1 overflow-hidden p-3 border">
            <Link className='text-uppercase text-body' to='#' onClick={() => { setChangePwd(true)}}><i className='fa fa-key font-weight-bold' /> Change Password</Link>
          </div>
          { changePwd && 
            <form className="p-2">
              <div className="form-group">
                {/** Password **/}
                <label htmlFor="password">
                  New Password:
                </label><br />
                <input type="password" id="password" onChange={(e) => setPwd(e.target.value)} value={pwd} className="p-2" required aria-invalid={"false"} aria-describedby="pwdnote" onFocus={() => setPwdFocus(true)} onBlur={() => setPwdFocus(false)} />
                <p id="pwdnote" className={pwdFocus ? "instructions" : "offscreen"}>
                  <i className='singleIcon far fa-info-circle' />
                  8 to 24 characters.<br />
                </p>
              </div>
              <div className="form-group">
                {/**Confirm Password **/}
                <label htmlFor="confirm_pwd">
                  Confirm New Password:
                  <i className={validMatch && matchPwd ? "valid singleIcon far fa-check" : "hide singleIcon far fa-check"} />
                  <i className={validMatch || !matchPwd ? "hide singleIcon far fa-times" : "invalid singleIcon far fa-times"} />
                </label><br />
                <input type="password" id="confirm_pwd" onChange={(e) => setMatchPwd(e.target.value)} className="p-2" value={matchPwd} required aria-invalid={validMatch ? "false" : "true"} aria-describedby="confirmnote" onFocus={() => setMatchFocus(true)} onBlur={() => setMatchFocus(false)} />
                <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                  <i className='far fa-info-circle' />
                  Must match the first password input field.
                </p>
              </div>
              <button className="btn btn-primary font-weight-semi-bold px-4" style={{height: '50px'}} type="submit" onClick={handleChangePwd} disabled={ !validMatch ? true : false}>Change</button>
              &nbsp;&nbsp;&nbsp;
              <button className="btn btn-primary font-weight-semi-bold px-4" style={{height: '50px'}} type="submit" onClick={() => setChangePwd(false)}>Cancel</button>  
            </form>
          }
        </div>
      </div>
      { (userStories.length>0 || userArticles.length>0) &&
        <>
          <div className="section-title">
            <h4 className="m-0 font-weight-bold">Content</h4>
            <Link to={`/user/posts/${userProfile.id}`}>View All</Link>
          </div>  
          <div className="bg-white border border-top-0 p-4">
            { userStories?.length>0 && <>
              <h5 className='font-weight-bold'>News Stories</h5>
              <hr />
              {userStories.map((value) => {
                return (
                  <div key={value.id} className='mx-0 mb-3'>
                    <small className="font-weight-semi-bold p-1 mr-2" style={{width: '115px'}}> {new Date(value.createdAt).toDateString()} </small>
                    <Link className="h6 d-block mb-3 text-secondary font-weight-bold p-1 mr-2" to={`/news/view/${value.id}`}>{value.title} </Link>
                  </div>
                )
              })}
            </>}
            { userArticles?.length>0 && <>
              <br /><h5 className='font-weight-bold'>Articles</h5><hr />
              { userArticles.map((value) => {
                return (
                  <div key={value.id} className='mx-0 mb-3'>
                    <small className="font-weight-semi-bold p-1 mr-2" style={{width: '115px'}}> {new Date(value.createdAt).toDateString()}</small>
                    <Link className="h6 d-block mb-3 text-secondary font-weight-bold p-1 mr-2" to={`/articles/view/${value.id}`}>{value.title}</Link>
                  </div>
                )
              })}
            </>}
            { userProfiles?.length>0 && <>
              <br /><h5 className='font-weight-bold'>Profiles</h5><hr />
              { userProfiles.map((value) => {
                return (
                  <div key={value.id} className='mx-0 mb-3'>
                    <small className="font-weight-semi-bold p-1 mr-2" style={{width: '115px'}}> {new Date(value.createdAt).toDateString()}</small>
                    <Link className="h6 d-block mb-3 text-secondary font-weight-bold p-1 mr-2" to={`/profiles/view/${value.id}`}>{value.profileTitle}</Link>
                  </div>
                )
              })}
            </>}
            { userGalleries?.length>0 && <>
              <br /><h5 className='font-weight-bold'>Galleries</h5><hr />
              { userGalleries.map((value) => {
                return (
                  <div key={value.id} className='mx-0 mb-3'>
                    <small className="font-weight-semi-bold p-1 mr-2" style={{width: '115px'}}> {new Date(value.createdAt).toDateString()}</small>
                    <Link className="h6 d-block mb-3 text-secondary font-weight-bold p-1 mr-2" to={`/gallery/view/${value.id}`}>{value.name}</Link>
                  </div>
                )
              })}
            </>}
          </div>
        </>
      }
    </div>
  )
}

export default UserDetails