/* eslint-disable jsx-a11y/anchor-has-content */
import {Link, useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import { useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import Select from 'react-select'

import BusinessCategories from '../../../components/CategoryList'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import 'react-phone-input-2/lib/style.css'

//Editor
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const ADD_PROFILE_URL = '/company/add'
const UPLOAD_FILE_URL = '/upsingle'
const PHONEREGEX = /^256[2347]\d{8}$/

export default function AddCompany({PF}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [profile, setProfile] = useState({
        category: '', website: '', profile:'',detail:'', email:'', phone:'', name:'', logo:''
    })
    const [errorPhone, setErrorPhone] = useState(false)
    const [holdPhone, setHoldPhone] = useState()
    const [file, setFile] = useState(null)
    const navigate = useNavigate()

    const onbio = (value) => {
        setProfile(prevProfile=>({...prevProfile, detail: value}))
    }

    useEffect(() => {
        const isPhoneValid = PHONEREGEX.test(holdPhone)
        setErrorPhone(!isPhoneValid)
        if (isPhoneValid) {setProfile(prevProfile => ({ ...prevProfile, phone: `+${holdPhone}`}))}
    }, [holdPhone, setProfile])

    const addProfile = async(e) => {
        e.preventDefault();

        if(file){
            if (!file.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
                alert("Wrong file type. Upload a jpg, jpeg or png");
                navigate(0);
            }else if (file.size > 700000) {
                alert("File is too large. Upload file less than 700kb");
                navigate(0);
            }else {
                const formData = new FormData();
                const filename = profile.name + '_' + Date.now() + '_' + file.name;            
                formData.append("name", filename);
                formData.append("file", file);
                profile.logo = filename
        
                try{
                    await axiosPrivate.post(UPLOAD_FILE_URL, formData);
                    alert('Image Posted');
                }catch(err){
                    console.log(err);
                }
            }
        }

        const newProfile = { ...profile, userId: authDecode.user.id};
        
        try {
            axiosPrivate.post(ADD_PROFILE_URL, newProfile).then(()=>{
                navigate('/admin/adverts');
            });   
        }catch (error) {
            console.log(error);  
        }
    }

    return (
        <div className="col-lg-8 mb-3">    
            <div className='main-form'>
                <form className="p-2" onSubmit={addProfile} data-toggle="validator">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="teachers-left mt-50">
                                <div className="hero">
                                    { file && <img className='rounded-circle mr-2' src={URL.createObjectURL(file)} style={{height: '200px', width: '200px'}} alt={profile.name} /> }
                                    <div className="form-group mt-3">
                                        <label htmlFor="fileInput">
                                            <h5>Add Logo: <i className="fa fa-recycle"></i></h5>
                                        </label>
                                        <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                                    </div>
                                    <div className="form-group">
                                        <b>Business Category </b>
                                        <Select defaultInputValue={profile.category} options={BusinessCategories} onChange={(e)=>{setProfile(prevProfile=>({ ...prevProfile, category: e.value}))}} />
                                    </div>
                                    <div className="form-group">
                                        <b><i className="icon fa fa-envelope-o" /> Email</b>
                                        <input type="text" value={profile.email} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setProfile(prevProfile=>({ ...prevProfile, email: e.target.value}))} />
                                    </div>
                                    <div className="form-group">
                                        <b><i className="fa fa-phone" /> Phone</b>
                                        <PhoneInput inputProps={{width: '100%', maxLength: 16, required: true}}
                                            className='mt-1' country={'ug'} onlyCountries={['ug']} onChange={setHoldPhone} value={profile.phone.slice(1)}
                                        />
                                        {(holdPhone && errorPhone) && <span className='error-message'><b><i className='bi bi-exclamation-circle'/></b> Invalid Phone Number Entered</span>}
                                    </div>
                                    <div className="form-group">
                                        <b><i className="icon fa fa-globe" /> Website</b>
                                        <input type="text" value={profile.website} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setProfile(prevProfile=>({ ...prevProfile, website: e.target.value}))} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 bg-white border p-3">
                            <div className='form-group mb-5'>
                                <h5 className='mt-3'>Business Name</h5>
                                <input type="text" value={profile.name} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setProfile(prevProfile=>({ ...prevProfile, name: e.target.value}))} />
                            </div>
                            <div className='form-group mt-3'>
                                <h5>Business Profile</h5>
                                <ReactQuill theme="snow" rows="15" value={profile.detail} onChange={onbio} />
                            </div>
                            <br />
                            <p className="form-message"></p>
                            <button type="submit" className="btn btn-primary font-weight-semi-bold px-4" disabled={!profile.name || !profile.email || !profile.phone || !profile.detail || !profile.category || !file }>Send</button>
                            &nbsp;&nbsp;&nbsp;
                            <Link className="btn btn-primary font-weight-semi-bold px-4" to='/admin/adverts'>Cancel</Link> 
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}