import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"

import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import './Components.scss'
import Card from "./Card"

const VIEW_CATEGORYLIST_URL = '/shop/products/'

const List = ({PF, catId }) => {
  const axiosPrivate = useAxiosPrivate();
  const [products, setProducts] = useState([]);
  let { id } = useParams();
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    axiosPrivate.get(VIEW_CATEGORYLIST_URL + id).then((response) => {
      isMounted && setProducts(response.data);
    }).catch((error) => {
        //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [id]);

  return (
    <div className="row">
      {products?.map((item) => <Card PF={PF} item={item} key={item.id} />)}
    </div>
  );
};

export default List;
