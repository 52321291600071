import { AuthProvider } from './assets/AuthProvider';
import { Route, Routes } from 'react-router-dom';

//Components
import PersistLogin from './components/PersistLogin'
import ScrollToTop from './components/ScrollToTop'
import RequireAuth from './components/RequireAuth'
import BottomBar from './components/BottomBar'
import Layout from './components/Layout'
import Navbar from './components/Navbar'

// Modules
import Articles from './modules/Articles'
import Profiles from './modules/Profiles'
import Library from './modules/Library'
import Gallery from './modules/Gallery'
import Home from './modules/home/Home'
import Events from './modules/Events'
import About from './modules/About'
import Admin from './modules/Admin'
import User from './modules/User'
import News from './modules/News'
import Shop from './modules/Shop'
import Alum from './modules/Alum'

import PageNotFound from './pages/PageNotFound'
import ComingSoon from './pages/ComingSoon'
import Register from './pages/Register'
import Login from './pages/Login'


function App() {

  let IMAGE_URL = 'http://localhost:5002';
  if(process.env.NODE_ENV === 'production'){ IMAGE_URL = 'https://www.budonian.com/app'; }
  
  const PF = `${IMAGE_URL}/images/`;

  return (
    <AuthProvider>
      <div className="App">
        <ScrollToTop />
        <Navbar PF={PF}/>
        <Routes>
          <Route element={<PersistLogin />}>
            <Route path="/" element={<Layout />}>
              <Route exact path='/' element={<Home PF={PF} />}/>
              <Route exact path='/articles/*' element={<Articles PF={PF} />} />
              <Route exact path='/profiles/*' element={<Profiles PF={PF} />} />
              <Route exact path='/gallery/*' element={<Gallery PF={PF} />} />
              <Route exact path='/library/*' element={<Library PF={PF} />} />
              <Route exact path='/events/*' element={<Events PF={PF} />} />
              <Route exact path='/about/*' element={<About PF={PF} />} />
              <Route exact path='/news/*' element={<News PF={PF} />} />
              <Route exact path='/register' element={<Register />} />               
              <Route exact path='/login' element={<Login />} />
              <Route exact path='/shop/*' element={<Shop PF={PF} />} />
              <Route exact path='/alum/*' element={<Alum PF={PF} />} />
              <Route exact path='/soon' element={<ComingSoon PF={PF} />}/>
              <Route element={<RequireAuth allowedRoles={[1, 2, 3, 4, 5]} />}>
                <Route exact path='/user/*' element={<User PF={PF} />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[4, 5]} />}>
                <Route exact path='/admin/*' element={<Admin PF={PF} />} />
              </Route>
              <Route exact path='/*' element={<PageNotFound />} />
            </Route>
          </Route>
        </Routes>
        <BottomBar PF={PF} />
      </div>
    </AuthProvider>
  );
}

export default App;