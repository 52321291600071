import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import './Components.scss'
import Card from "./Card"

const VIEW_PRODUCTS_URL = '/shop/'

const FeaturedProducts = ({ PF, type }) => {
  const axiosPrivate = useAxiosPrivate();
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();  

  useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      axiosPrivate.get(VIEW_PRODUCTS_URL + type).then((response) => {
          isMounted && setProducts(response.data);
          
      }).catch((error) => {
          //Handle Errors Coming out of this
      });
      return () => { isMounted = false; controller.abort();  }
      // eslint-disable-next-line
  }, [type]);

  return (
    <div className="featuredProducts mt-3">
      <div className="section-title">
         <h4 className="m-0 text-uppercase font-weight-bold">{type} Products</h4>
      </div>
      <div className="bottom row">
        { products?.map((item) => <Card PF={PF} item={item} key={item.id}  onClick={() => navigate(`/shop/view/${item.id}`)}/>)}
      </div>
    </div>
  );
};

export default FeaturedProducts;
