import { Link, useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Select from 'react-select'

import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

const COMMITTEES_URL = '/admin/committees/obuc/'

const CommsAddOBC = () => {
    let {id} = useParams();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [profiles, setProfiles] = useState([]);
    const [startYear, setStartYear] = useState('');
    const [endYear, setEndYear] = useState('');
    const [newPresident, setNewPresident] = useState('');
    const [newVP1, setNewVP1] = useState('');
    const [newVP2, setNewVP2] = useState('');
    const [newGS, setNewGS] = useState('');
    const [newTreasurer, setNewTreasurer] = useState('');
    const [newPR, setNewPR] = useState('');
    const [newMobiliser, setNewMobiliser] = useState('');
    const [newY1Rep, setNewY1Rep] = useState('');
    const [newY2Rep, setNewY2Rep] = useState('');
    // eslint-disable-next-line
    const [isClearable, setIsClearable] = useState(true);
    // eslint-disable-next-line
    const [isSearchable, setIsSearchable] = useState(true);
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(COMMITTEES_URL + id).then((response)=>{
            isMounted && setProfiles(response.data.profiles);
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, []);

    const handleSubmitExecutive = (e) => {
        e.preventDefault();

        const newExco = [
            {commId: 301, startYear: startYear, endYear: endYear, profileId: newPresident.value},
            {commId: 302, startYear: startYear, endYear: endYear, profileId: newVP1.value},
            {commId: 303, startYear: startYear, endYear: endYear, profileId: newVP2.value},
            {commId: 304, startYear: startYear, endYear: endYear, profileId: newGS.value},
            {commId: 305, startYear: startYear, endYear: endYear, profileId: newTreasurer.value},
            {commId: 306, startYear: startYear, endYear: endYear, profileId: newPR.value},
            {commId: 307, startYear: startYear, endYear: endYear, profileId: newMobiliser.value},
            {commId: 308, startYear: startYear, endYear: endYear, profileId: newY1Rep.value},
            {commId: 309, startYear: startYear, endYear: endYear, profileId: newY2Rep.value},
        ];
        
        try {
            axiosPrivate.post(COMMITTEES_URL + 'add', newExco).then(()=>{
            alert('New Executive Added');
            navigate('/admin/committees')
            }).catch( function (error) { console.log(error.config); });   
        }catch (error) { console.log(error); }
    }

    return (
        <div className="col-lg-8">
            <div className="section-title">
                <h4 className="m-0 text-uppercase font-weight-bold">Add New Committee</h4>
            </div>
            <form onSubmit={handleSubmitExecutive}>
                <div className='mb-3 p-2'>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Tenure: </div>
                        <div className="col-lg-8">
                            <input type='text' placeholder='Start Year' className="p-1"onChange={(e)=>{setStartYear(e.target.value)}}/>
                            &nbsp;&nbsp;
                            <input type='text' placeholder='End Year' className="p-1"onChange={(e)=>{setEndYear(e.target.value)}}/>
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">President: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewPresident(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">1st Vice President: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewVP1(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">2nd Vice President: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewVP2(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">General Secretary: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewGS(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Treasurer: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewTreasurer(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Public Relations: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewPR(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">Mobiliser: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewMobiliser(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">1st Year Representative: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewY1Rep(e)}} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-4">2nd Year Representative: </div>
                        <div className="col-lg-8">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setNewY2Rep(e)}} />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-lg-4">&nbsp;</div>
                        <div className="col-lg-8">
                            <button className="btn btn-primary font-weight-semi-bold px-4" type="submit" disabled={!startYear || !endYear || !newPresident || !newVP1 || !newVP2 || !newGS || !newTreasurer || !newPR || !newMobiliser || !newY1Rep || !newY2Rep}>Submit</button>
                            &nbsp;&nbsp;
                            <Link className="btn btn-primary font-weight-semi-bold px-4" to='/admin/committees'>Cancel</Link>
                        </div>
                        <hr />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CommsAddOBC
