import RightSideBar from '../../components/RightSideBar'
import FeatureSlider from './FeatureSlider'
import HomeContent from './HomeContent'
import HomeSlider from './HomeSlider'

function Home({PF}) {  
    return (
      <>
        <FeatureSlider />
        <HomeSlider PF={PF} />
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <HomeContent PF={PF} />
              <RightSideBar PF={PF}/>
            </div>
          </div>
        </div>
      </>
    )
}

export default Home