import { Link, useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Select from 'react-select'

import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

const COMMITTEES_URL = '/admin/committees/obuc/'

function CommsOBUC({PF}) {
    let {id} = useParams();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [executive, setExecutive] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [replaceExec, setReplaceExec] = useState(false);
    const [oldExec, setOldExec] = useState('');
    const [newExec, setNewExec] = useState('');
    // eslint-disable-next-line
    const [isClearable, setIsClearable] = useState(true);
    // eslint-disable-next-line
    const [isSearchable, setIsSearchable] = useState(true);
    let executives = [];
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(COMMITTEES_URL + id).then((response)=>{
            if(isMounted){ 
                setExecutive(response.data.exco);
                setProfiles(response.data.profiles);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, []);

    for(let i=0;i<executive.length;i++){
        executives.push({value: executive[i].profileId, label: executive[i].profile.profileTitle});
    }

    const handleReplaceExecutive = async(e, profileId) => {   
        e.preventDefault();
        const updateExec = {old: oldExec.value, new: newExec.value}

        try {
            axiosPrivate.put(COMMITTEES_URL + id, updateExec).then(()=>{
              alert('Executive Member Replaced');
              window.location.reload();
            }).catch( function (error) { console.log(error.config); });   
        }catch (error) { console.log(error); }
    }

    const handleCommitteeDelete = async(e) => {
        e.preventDefault();         
        try {
            axiosPrivate.delete(COMMITTEES_URL + id).then(()=>{
              alert('Committee Deleted');
              navigate('/admin/committees')
            }).catch( function (error) { console.log(error.config); });   
        }catch (error) { console.log(error); }
    }

    return (
    <div className="col-lg-8">
        <div className="section-title">
            <h4 className="m-0 text-uppercase font-weight-bold">University Chapter Executive - {id}</h4>
            <div>
                <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" to='' onClick={(e)=> {handleCommitteeDelete(e)}}  style={{cursor: 'pointer'}}><i className="singleIcon fa fa-trash" /> Delete</Link>
                <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-2" to='' onClick={(e)=> {setReplaceExec(true)}}  style={{cursor: 'pointer'}}><i className="singleIcon fa fa-recycle" /> Replace</Link>
            </div>
        </div>
        { replaceExec && 
            <>
                <div className="bg-white align-items-center mb-2 overflow-hidden p-3 border">
                    <b>Replace Member</b>
                </div>
                <form onSubmit={handleReplaceExecutive}>
                    <div className='mb-3 p-2'>
                        <div className="mb-2 row">
                            <div className="col-lg-2">Current: </div>
                            <div className="col-lg-10">
                                <Select placeholder="Search for Name" options={executives} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => setOldExec(e)} />
                            </div>
                        </div>
                        <div className="mb-2 row">
                            <div className="col-lg-2">New: </div>
                            <div className="col-lg-10">
                                <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => setNewExec(e)} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-lg-2">&nbsp;</div>
                            <div className="col-lg-10">
                                <button className="btn btn-primary font-weight-semi-bold px-4" type="submit" disabled={!oldExec || !newExec}>Replace</button>
                                &nbsp;&nbsp;
                                <button className="btn btn-primary font-weight-semi-bold px-4" onClick={(e) => {setReplaceExec(false); setOldExec(''); setNewExec('');}}>Cancel</button>
                            </div>
                            <hr />
                        </div>
                    </div>
                </form>
            </>
        }
        <div className="row mb-3">
            { executive && executive.map((item, index) => {
                return(
                <div className='col-4 position-relative mb-3' key={index}>
                    <div className='position-relative overflow-hidden' style={{maxHeight: '120px'}}>
                        <img className='img-fluid w-100' src={PF + item.profile.pictures} alt='' style={{objectFit: 'cover', cursor: 'pointer'}} />
                    </div>
                    <small>
                        <Link className="d-block text-secondary" to={`/profiles/view/${item.profileId}`}>{item.profile.profileTitle}<br /></Link>
                        Office: {item.comm.description}<br />
                    </small>
                </div>
                )           
            })}
        </div>
    </div>
    )
}

export default CommsOBUC