import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import CategoryNav from "../components/CategoryNav"

const VIEW_ORDER_URL = '/shop/viewOrder/'
const CONFIRM_ORDER_URL = '/shop/pay/'

const Payment = ({PF}) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [order, setOrder] = useState({});
  const [orderItems, setOrderItems] = useState([])
  const [vendor, setVendor] = useState('');
  const [mmCode, setMmCode] = useState('');
  let {id} = useParams();
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    
    axiosPrivate.get(VIEW_ORDER_URL + id).then((response) => {
      if(isMounted){
        setOrder(response.data.order);
        setOrderItems(response.data.orderItems);
      }
    }).catch((error) => {
        //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [id]);

  const handlePayment = async (e) => {
    e.preventDefault();
    let confirmOrder = {vendor, mmCode};
    
    try {
      await axiosPrivate.put(CONFIRM_ORDER_URL + id, confirmOrder).then((response)=>{
        navigate(`/shop/confirmation/${id}`);
      });   
    }catch (error) {
       console.log(error);  
    }
  };

  return (
    <div className="container container-fluid">
      <CategoryNav />
      <div className="mt-3">
        <h2 className="mb-3 text-secondary font-weight-bold">ORDER # - {id} : TOTAL - {order?.amount}</h2>
      </div>
      <div className="product row">
        <div className="item col-lg-8">
          { orderItems.map((item) => (
            <div className="row mb-2 overflow-hidden" key={item.id} style={{height: '100px'}}>
              <div className="col-2">
                <img src={PF + item.image} className="img-fluid w-100 h-100" style={{objectFit: 'cover'}} alt="" />
              </div>
              <div className="col-10">
                <div className="details">
                  {item.title}
                  <div className="price"><small>{item.quantity} x {item.price.toLocaleString('en')}/-</small></div>
                </div>
              </div>
            </div>
          ))}
          <div className="row mb-2 overflow-hidden" style={{height: '100px'}}>
            <div className="col-2 item">
              <img src={PF + 'Delivery.jpg'} className="img-fluid w-100 h-100" style={{objectFit: 'cover'}} alt="" />
            </div>
            <div className="col-10 item">
              <div className="details">
                Delivery Charges
                <div className="price"><small>5,000/-</small></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <h5>Select Payment Option</h5>
          <div className="mb-2 overflow-hidden">
            <img className="img-fluid" src={PF + 'MTN.svg'} alt='' style={{width: '75px', height: '75px', cursor: 'pointer'}} onClick={()=>setVendor('MTN')} />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <img className="img-fluid" src={PF + 'Airtel.svg'} alt='' style={{width: '75px', height: '75px', cursor: 'pointer'}} onClick={()=>setVendor('Airtel')} />
          </div>
          <br />
          {vendor &&
            <> 
              <img className="img-fluid" src={PF + vendor + 'Pay.jpg'} alt=''/>
              <br /><br />
              <input type='text' placeholder='Enter Transaction ID Here' className="form-control p-2 mb-3" autoFocus={true} onChange={(e)=>setMmCode(e.target.value)}  />
            </>
          }
          <button className="btn btn-primary font-weight-semi-bold px-4" disabled={!vendor || !mmCode} onClick={(e) => handlePayment(e)}>Confirm Payment</button>
        </div>
        
      </div>
    </div>
  );
};

export default Payment;
