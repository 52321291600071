import { Routes, Route } from 'react-router-dom'

import RightSideBar from '../components/RightSideBar'
import RequireAuth from '../components/RequireAuth'
import ViewGalleries from './gallery/Galleries'
import ViewGallery from './gallery/ViewGallery'
import AddPictures from './gallery/AddPictures'
import EditPicture from './gallery/EditPicture'
import AddGallery from './gallery/AddGallery'

function Gallery({PF}) {
  return (
    <div className='container-fluid pt-5 mb-3'>
      <div className="container">
        <div className='row'>
          <Routes>
            <Route exact path='/' element={<ViewGalleries PF={PF} />} />
            <Route exact path='/view/:id' element={<ViewGallery PF={PF} />} />
            <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
              <Route exact path='/add' element={<AddGallery PF={PF} />} />
              <Route exact path='/addpics/:id' element={<AddPictures PF={PF} />} />
              <Route exact path='/edit/:id' element={<EditPicture PF={PF} />} />
            </Route>
          </Routes>
          <RightSideBar PF={PF} />
        </div>
      </div>
    </div> 
  )
}

export default Gallery