/* eslint-disable jsx-a11y/anchor-has-content */
import {Link, useParams, useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import Select from 'react-select'

import BusinessCategories from '../../../components/CategoryList'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import 'react-phone-input-2/lib/style.css'

//Editor
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

//Company
const DELETE_PROFILE_URL = '/company/delete/'
const EDIT_PROFILE_URL = '/company/edit/'
const VIEW_PROFILE_URL = '/company/view/'

//Ads
const DELETE_ADVERT_URL = '/advert/delete/'
const CREATE_ADVERT_URL = '/advert/add/'

//Files
const DELETE_FILE_URL = '/fileDelete/'
const UPLOAD_FILE_URL = '/upsingle'

const PHONEREGEX = /^256[2347]\d{8}$/

export default function ViewCompany({PF}) {
    const { auth } = useAuth()
    const axiosPrivate = useAxiosPrivate()
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [profile, setProfile] = useState({
        category: '', website: '', profile:'',detail:'', email:'', phone:'', name:'', logo:''
    })
    const [updateMode, setUpdateMode] = useState(false)
    const [errorPhone, setErrorPhone] = useState(false)
    const [displayAd, setDisplayAd] = useState(null)
    const [holdPhone, setHoldPhone] = useState()
    const [newAd, setNewAd] = useState(false)
    const [main, setMain] = useState('')
    const [file, setFile] = useState(null)
    const navigate = useNavigate()
    let {id} = useParams()

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_PROFILE_URL + id).then((response)=>{
            isMounted && setProfile(response.data)
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        const isPhoneValid = PHONEREGEX.test(holdPhone)
        setErrorPhone(!isPhoneValid)
        if (isPhoneValid) {setProfile(prevProfile => ({ ...prevProfile, phone: `+${holdPhone}`}))}
    }, [holdPhone, setProfile])

    const onbio = (value) => {
        setProfile(prevProfile=>({...prevProfile, detail: value}))
    }

    const updateAdvertiser = async(e) => {
        e.preventDefault();
        const profileId = profile.id
     
        if(file){
            if (!file.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
                alert("Wrong file type. Upload a jpg, jpeg or png")
                navigate(0)
            }else if (file.size > 700000) {
                alert("File is too large. Upload file less than 700kb")
                navigate(0)
            }else {
                axiosPrivate.post(DELETE_FILE_URL + profile.picture).then(()=>{
                    console.log('Old image deleted')
                }).catch(function(error){
                    console.log(error.config)
                })
 
                const formData = new FormData();
                const filename = profile.name + '_' + Date.now() + '_' + file.name          
                formData.append("name", filename)
                formData.append("file", file)
                profile.logo = filename
        
                try{
                    await axiosPrivate.post(UPLOAD_FILE_URL, formData)
                    alert('Image Posted');
                }catch(err){
                    console.log(err)
                }
            }
        }
        
        const updatedProfile = { ...profile, userId: authDecode.user.id }

        try {
            axiosPrivate.put(EDIT_PROFILE_URL + profileId, updatedProfile).then(()=>{
                alert('Company Profile Edited');
                setUpdateMode(false);
                window.location.reload();
            });   
        }catch (error) {
            console.log(error);  
        }
    }

    const deleteAdvertiser = async(e, id) => {
        e.preventDefault()
        try {
            axiosPrivate.post(DELETE_FILE_URL + profile.picture).then(()=>{
                console.log('Old image deleted');
            }).catch(function(error){
                console.log(error.config);
            });

            axiosPrivate.delete(DELETE_PROFILE_URL + id).then(()=>{
                alert('Company Profile Deleted');
                navigate('/admin/adverts');
            }).catch(function(error){
                console.log(error.config);
            });               
        } catch(error){
            console.log(error);
        }
    }

    const deleteAdvert = async (e, id, adpic)=>{
        e.preventDefault()
        try {
            axiosPrivate.post(DELETE_FILE_URL + adpic).then(()=>{
                console.log('Old image deleted')
            }).catch(function(error){
                console.log(error.config)
            })

            axiosPrivate.delete(DELETE_ADVERT_URL + id).then(()=>{
                alert('Company Profile Deleted')
                window.location.reload()
            }).catch(function(error){
                console.log(error.config)
            })              
        } catch(error){
            console.log(error)
        }
    }

    const createAdvert = async (e, id)=>{
        e.preventDefault()
        const newAd = { main, published: true, views: 0, companyId: profile.id, userId: authDecode.user.id, };

        if(file){
          const formData = new FormData();
          const filename = Date.now() + file.name;
          
          formData.append("name", filename);
          formData.append("file", file);
          newAd.picture = filename;
    
          try{
            await axiosPrivate.post(UPLOAD_FILE_URL, formData);
          }catch(err){
            console.log(err)
          }
          URL.revokeObjectURL(file)
        }
      
        try {
          await axiosPrivate.post(CREATE_ADVERT_URL, newAd).then((response)=>{
            alert('Advert Created')
            setNewAd(false)
            window.location.reload()
          })   
        }catch (error) {
          console.log(error) 
        }
    }

    return (
        <div className="col-lg-8 mb-3">
            <div className="section-title" style={{padding: '5px 15px 3px 15px'}}>
                <div className="hero d-flex align-items-center" style={{height: '50px', width: '50px'}}>
                    { file ? (
                        <img className='rounded-circle mr-2' src={URL.createObjectURL(file)} style={{objectFit: 'cover', height: '50px',  width: '50px'}} alt={profile.name} />
                    ): (
                        profile?.logo && <img className='rounded-circle mr-2 img-fluid h-100 w-100' src={PF + profile?.logo}  style={{objectFit: 'cover', height: '50px',  width: '50px'}} alt={profile.name} />
                    )}
                </div>
                <h4 className="m-0 text-uppercase font-weight-bold">{profile?.name}</h4>
            </div>    
            { updateMode ? 
                <div className='main-form'>
                    <form className="p-2" onSubmit={updateAdvertiser} data-toggle="validator">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="teachers-left mt-50">
                                    <div className="form-group mt-3">
                                        <label htmlFor="fileInput">
                                            <h5>Change Logo: <i className="fa fa-recycle"></i></h5>
                                        </label>
                                        <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                                    </div>
                                    <div className="form-group">
                                        <b>Business Category </b>
                                        <Select defaultInputValue={profile.category} options={BusinessCategories} onChange={(e)=>{setProfile(prevProfile=>({ ...prevProfile, category: e.value}))}} />
                                    </div>
                                    <div className="form-group">
                                        <b><i className="icon fa fa-envelope-o" /> Email</b>
                                        <input type="text" value={profile.email} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setProfile(prevProfile=>({ ...prevProfile, email: e.target.value}))} />
                                    </div>
                                    <div className="form-group">
                                        <b><i className="fa fa-phone" /> Phone</b>
                                        <PhoneInput inputProps={{width: '100%', maxLength: 16, required: true}}
                                            className='mt-1' country={'ug'} onlyCountries={['ug']} onChange={setHoldPhone} value={profile.phone.slice(1)}
                                        />
                                        {(holdPhone && errorPhone) && <span className='error-message'><b><i className='bi bi-exclamation-circle'/></b> Invalid Phone Number Entered</span>}
                                    </div>
                                    <div className="form-group">
                                        <b><i className="icon fa fa-envelope-o" /> Email</b>
                                        <input type="text" value={profile.website} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setProfile(prevProfile=>({ ...prevProfile, website: e.target.value}))} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 bg-white border p-3">
                                <div className='form-group mb-5'>
                                    <h5 className='mt-3'>Business Name</h5>
                                    <input type="text" value={profile.name} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setProfile(prevProfile=>({ ...prevProfile, name: e.target.value}))} />
                                </div>
                                <div className='form-group mt-3'>
                                    <h5>Business Profile</h5>
                                    <ReactQuill theme="snow" rows="15" value={profile.detail} onChange={onbio} />
                                </div>
                                <br />
                                <p className="form-message"></p>
                                <button type="submit" className="btn btn-primary font-weight-semi-bold px-4" disabled={!profile.name || !profile.email || !profile.phone || !profile.detail || !profile.category }>Send</button>
                                &nbsp;&nbsp;&nbsp;
                                <Link className="btn btn-primary font-weight-semi-bold px-4" to='#cancel' onClick={(e)=>setUpdateMode(false)} >Cancel</Link> 
                            </div>
                        </div>
                    </form>
                </div>
            :                    
                <>
                    <div className="justify-content-center m-2">
                        <div className="mb-1 row">
                            <div className='bg-white p-3 col-lg-4'>
                                <b>Business Category</b><br />
                                {profile.category}
                            </div>
                            <div className='bg-white p-3 col-lg-5'>
                                <b>Contact Details</b><br />
                                <i className="fa fa-phone" aria-hidden="true" /> {profile.phone && [profile.phone.slice(0, 4), ' ', profile.phone.slice(4,7), ' ', profile.phone.slice(7,10), ' ', profile.phone.slice(10)].join('')}<br />
                                <i className="fa fa-envelope" aria-hidden="true" /><Link to='#email' onClick={(e)=>{ window.location = `mailto:${profile.email}`}}> {profile.email}</Link><br />
                                <i className="fa fa-globe" aria-hidden="true" /> <a target="_blank" rel="noreferrer" href={`https://${profile.website}`}> {profile.website}</a><br />
                            </div>
                            <div className='bg-white p-3 col-lg-3 align-content-center justify-content-center'>
                                <Link to='#edit'><i className="fa fa-edit" aria-hidden="true" onClick={() => setUpdateMode(true)}/></Link>
                                &nbsp;&nbsp; Edit<br/><br/>
                                <Link to='#delete'><i className="fa fa-trash" aria-hidden="true" onClick={() => deleteAdvertiser(profile.id)} /></Link>
                                &nbsp;&nbsp; Delete
                            </div>
                        </div>
                    </div>
                    { newAd &&
                        <div className='main-form'>
                            <form onSubmit={createAdvert} data-toggle="validator">
                                <div className='row mb-2'>
                                    <div className={main ? 'col-lg-4' : 'col-lg-6'}>
                                        <div className="form-group">
                                            <label htmlFor="fileInput">
                                                <h5>Location: </h5>
                                            </label>
                                            <select value={ main } onChange={(e)=>setMain(e.target.value)} className="form-control p-2">
                                                <option value={null}>Select Location</option>
                                                <option value={true}>Main</option>
                                                <option value={false}>Sidebar</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-5">
                                            <label htmlFor="fileInput">
                                                <h5>Image: <i className="fa fa-file-image"></i></h5>
                                            </label>
                                            <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                                        </div>
                                        <div className='p-2 align-content-center justify-content-center'>
                                            <button type="submit" className="btn btn-primary font-weight-semi-bold px-3" disabled={!file || main===''}>Send</button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Link className="btn btn-primary font-weight-semi-bold px-3" to='#cancel' onClick={(e)=>setNewAd(false)}>Cancel</Link> 
                                        </div>
                                    </div>
                                    <div className={ main ? 'col-lg-8' : 'col-lg-6'}>{ file && <img className='mr-2 img-fluid h-100' src={URL.createObjectURL(file)} style={{objectFit: 'cover', height: '120px'}} alt={profile.name} />}</div>
                                </div>

                            </form>
                        </div>
                    }
                    { (displayAd!==null) &&
                        <div className='row mb-2'>
                            <div className={profile?.adverts[displayAd].main ? 'col-lg-4' : 'col-lg-6'}>
                                Impressions: <b>{profile.adverts[displayAd].views}</b><br />
                                Location: <b>{profile.adverts[displayAd].main ? 'Main' : 'Sidebar'}</b><br />
                                Active Since: <b>{new Date(profile.adverts[displayAd].createdAt).toDateString()}</b><br /><br />
                                Close:&nbsp;&nbsp;<Link to='#close'><i className="fa fa-times" aria-hidden="true" onClick={() => setDisplayAd(null)} /></Link><br/>
                                Delete: <Link to='#delete'><i className="fa fa-trash" aria-hidden="true" onClick={(e) => deleteAdvert(e, profile?.adverts[displayAd].id, profile?.adverts[displayAd].picture)} /></Link>
                                &nbsp;&nbsp; 
                            </div>
                            <div className={profile?.adverts[displayAd].main ? 'col-lg-8' : 'col-lg-6'}><img className='mr-2 img-fluid h-100' src={PF + profile?.adverts[displayAd].picture}  style={{objectFit: 'cover', height: '120px'}} alt={profile.name} /></div>
                        </div>
                    }
                    <div className="bg-white p-3">
                        <h5>Adverts</h5>
                        <div className='row'>
                            { profile?.adverts && profile.adverts.map((advert, idx)=>{ 
                                return(
                                    <div key={idx} className='col-lg-1 mb-2' onClick={(e)=>setDisplayAd(idx)}>
                                        <img className='mr-2 img-fluid h-100 w-100' src={PF + advert.picture}  style={{objectFit: 'cover', height: '80px'}} alt={profile.name} />
                                    </div>
                                )
                            })}
                            <div className='col-lg-1 mb-2 px-3 py-2'>
                                <i className="singleIcon fa fa-plus text-3xl" aria-hidden="true" onClick={(e)=> {setNewAd(true); setDisplayAd(null)}}/>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}