import {Link, useNavigate} from 'react-router-dom'
import { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'

import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth'

const DELETE_SHELF_URL = '/library/delete/'
const EDIT_SHELF_URL = '/library/edit/'
const ADD_SHELF_URL = '/library/add'
const VIEW_LIBRARY_URL = '/library'

function ShelvesContent({PF}) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [library, setLibrary] = useState([]);
  const [addBookShelf, setAddBookShelf] = useState(false);
  const [editShelf, setEditShelf] = useState(false);
  const [expand, setExpand] = useState(false);
  const [shelfNumber, setShelfNumber] = useState(0);

  //const location = useLocation();  
  const navigate = useNavigate();  
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_LIBRARY_URL).then((response) => {
      isMounted && setLibrary(response.data);
    }).catch((error) => {
      //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, []);

  const addShelf = async (e) => {
    e.preventDefault();
    const newShelf = {name, description, archive: 0, userId: authDecode.user.id};
      
    try {
      await axiosPrivate.post(ADD_SHELF_URL, newShelf).then((response)=>{
        alert('Bookshelf Added')
        setAddBookShelf(false);
        navigate('/library');
      });   
    }catch (error) {
      console.log(error);  
    }
  };

  const deleteShelf = async(id) => {
    try {
      axiosPrivate.delete(DELETE_SHELF_URL + id).then(()=>{
        alert('Bookshelf Deleted');
        navigate('/library');
      });               
    } catch (error) {       
    }
  }

  const updateShelf = async(e, id) => {
    e.preventDefault();
    const updatedShelf = {name, description, userId: authDecode.user.id, id: id};
    try {
      axiosPrivate.put(EDIT_SHELF_URL + id, updatedShelf).then(()=>{
        setEditShelf(false);
        alert('Bookshelf Edited');
        window.location.reload();
      });   
    }catch (error) {
        console.log(error);  
    }
  }

  const handleExpand = (index) => {
    setShelfNumber(index)
    setExpand(true)
  }

  const handleEditShelf = (index, name, description) => {
    setShelfNumber(index)
    setEditShelf(true)
    setName(name)
    setDescription(description)
  }

  const handleFileDownload = (docFile) => {
    axiosPrivate.get(PF + docFile, {responseType: 'blob'}).then((response) => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(response.data);
            
      // Setting various property values
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = docFile;
      alink.click();
    })
  };

  return (
    <div className="col-lg-8 mb-3">
        <div className="section-title">
          <h4 className="m-0 text-uppercase font-weight-bold">Documents Library</h4>
          {(authDecode && authDecode?.user?.role >=3) && 
            <span className="text-secondary font-weight-medium text-decoration-none" onClick={(()=>{setAddBookShelf(!addBookShelf)})}>
              {addBookShelf ? <i className="writeIcon fas fa-minus" /> : <i className="writeIcon fas fa-plus" />} Add Collection
            </span>
          }
        </div>
        {addBookShelf && 
          <form action="" onSubmit={addShelf} className='mb-5'>
            <div className="form-group">
              <input type="text" placeholder="Shelf Name" className="form-control p-4" autoFocus={true} onChange={(e)=>setName(e.target.value)} />
            </div>
            <div className="form-group">
              <textarea placeholder="Shelf description ..." type="text" className="form-control p-4" rows="4" onChange={(e)=>setDescription(e.target.value)} />
            </div>
            <button className="btn btn-primary font-weight-semi-bold px-4" disabled={!name || !description} type="submit">Submit</button>
            &nbsp;&nbsp;&nbsp;  
            <Link className="btn btn-primary font-weight-semi-bold px-4" to='/library' onClick={()=>setAddBookShelf(false)}>Cancel</Link>  
          </form>
        }
        { library.length>0 ? library.map((value, index)=>{
          return (
            <div key={value.id} className='position-relative mb-1 text-dark ml-1 mr-1'>
              { (editShelf && shelfNumber===value.id) ? 
                <form action="" className='mb-5'>
                  <div className="form-group">
                    <input type="text"value={name} className="form-control p-4" autoFocus={true} onChange={(e)=>setName(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <textarea value={description} type="text" className="form-control p-4" rows="4" onChange={(e)=>setDescription(e.target.value)} />
                  </div>
                  <button className="btn btn-primary font-weight-semi-bold px-4" disabled={!name || !description} type="submit" onClick={(e)=>updateShelf(e, value.id)}>Submit</button>
                  &nbsp;&nbsp;&nbsp;  
                  <Link className="btn btn-primary font-weight-semi-bold px-4" to='/library' onClick={()=>setEditShelf(false)}>Cancel</Link>  
                </form>   
              : 
                <div className='row'>
                  <div className="col-lg-9 bg-white p-2">
                    <img src='/img/cabinet.png' width='25px' alt='Cabinet' className='mr-2'/> 
                    <Link className='text-dark font-weight-bold' to={`/library/view/${value.id}`}>{value.name}</Link>
                  </div>
                  <div className='col-lg-3 align-items-right bg-white p-2'>
                    {(expand && shelfNumber === value.id) ? <i className='fa fa-caret-up mr-2 ml-5' onClick={()=>setExpand(false)} /> : <i className='fa fa-caret-down mr-2 ml-5'  onClick={()=>handleExpand(value.id)} />}                 
                    {authDecode && <>
                      <i className='singleIcon far fa-edit mr-2' onClick={()=>handleEditShelf(value.id, value.name, value.description)}/>
                      <i className='singleIcon far fa-trash-alt' onClick={()=>deleteShelf(value.id)} />
                    </>}
                  </div>
                  {(expand && shelfNumber === value.id) && 
                    <div className='col-12 mt-3'>
                      {value.documents.length>0 && value.documents.map((document, index)=>{
                        return(
                          <div className='position-relative mt-2 mb-4 row pl-2' key={index}>
                            <div className='col-lg-10'>
                              <i className='fa fa-caret-right mr-2' />
                              {document.description}
                            </div>
                            <div className="singleEdit col-lg-2">
                              <i className='singleIcon fa fa-download mr-3' aria-hidden="true" onClick={()=>handleFileDownload(document.document)}/>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  }                 
                </div>
              }
            </div>
          );
        }) 
        : 
          <div className='mt-30 mb-300'>
            <div className='blog-details pt-45 mb-200'>
              <p>There are no documents at the moment.<br /><br /></p> <h6><Link to='/'>Return to the Home Page</Link></h6>
            </div>
          </div>
        }
    </div>
  )
}

export default ShelvesContent