import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'

import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

const COMMITTEES_URL = '/admin/committees'

function CommsAll({PF}) {
  const axiosPrivate = useAxiosPrivate();

  const [activeTrustees, setActiveTrustees] = useState({});  
  const [retiredTrustees, setRetiredTrustees] = useState({});  
  const [listExco, setListExco] = useState([]);  
  const [listObuc, setListObuc] = useState([]);
  const [listAdmins, setListAdmins] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [replaceAdmin, setReplaceAdmin] = useState(false);
  const [oldAdmin, setOldAdmin] = useState('');
  const [newAdmin, setNewAdmin] = useState('');
  // eslint-disable-next-line
  const [isClearable, setIsClearable] = useState(true);
  // eslint-disable-next-line
  const [isSearchable, setIsSearchable] = useState(true);
  let administrators = [];

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    axiosPrivate.get(COMMITTEES_URL).then((response)=>{
      if(isMounted){
        setActiveTrustees(response.data.activeTrustees);
        setRetiredTrustees(response.data.retiredTrustees);
        setListExco(response.data.obc);
        setListObuc(response.data.obuc);
        setListAdmins(response.data.admins);
        setProfiles(response.data.profiles);

      }
    }).catch((error) => {
      //Handle Errors Coming out of this
    });

    return () => { isMounted = false; controller.abort(); }
    // eslint-disable-next-line
  }, []);

  for(let i=0;i<listAdmins.length;i++){
    administrators.push({value: listAdmins[i].profileId, label: listAdmins[i].profile.profileTitle});
}

  const handleReplaceAdmin = async(e, profileId) => {   
    e.preventDefault();
    const updateExec = {old: oldAdmin.value, new: newAdmin.value}

    try {
        axiosPrivate.put(COMMITTEES_URL + '/admins', updateExec).then(()=>{
          alert('Administrator Replaced');
          window.location.reload();
        }).catch( function (error) { console.log(error.config); });   
    }catch (error) { console.log(error); }
}

  return (
    <div className="col-lg-8">
      <div className="section-title">
        <h4 className="m-0 text-uppercase font-weight-bold">Committees</h4>
      </div>
      <div className="section-title">
        <h4 className="m-0 font-weight-bold">Trustees</h4>
      </div>
      <div className="row mb-3">
        {activeTrustees?.profile && 
          <div className='col-lg-4'>
            <Link className="h6 d-block mb-3 text-secondary p-2" to={`/admin/committees/trustees/current`}>
              <div className='position-relative overflow-hidden' style={{maxHeight: '150px'}}>
                <img className='img-fluid w-100' src={PF + activeTrustees?.profile.pictures} alt='' style={{objectFit: 'cover', cursor: 'pointer'}} />
              </div>
              Current Members
            </Link>
          </div>
        }
        { retiredTrustees?.profile &&
          <div className='col-lg-4'>
            <Link className="h6 d-block mb-3 text-secondary p-2" to={`/admin/committees/trustees/former`}>
              <div className='position-relative overflow-hidden' style={{maxHeight: '150px'}}>
                <img className='img-fluid w-100' src={PF + retiredTrustees.profile.pictures} alt='' style={{objectFit: 'cover', cursor: 'pointer'}} />
              </div>
              Former Members
            </Link>
          </div>
        }
      </div>
      <div className="section-title">
        <h4 className="m-0 font-weight-bold">Executive Committee</h4>
        <Link className="badge badge-primary text-uppercase font-weight-semi-bold mr-1" to='/admin/committees/obc/add'>+ New Exec</Link>
      </div>
      <div className="row mb-3">
        { listExco?.length>0 && listExco?.map((item) => {
          return(
            <div key={item.profile.pictures} className='col-lg-2'>
              <Link className="h6 d-block mb-3 text-secondary p-2" to={`/admin/committees/obc/${item.startYear}`}>
                <div className='position-relative overflow-hidden' style={{maxHeight: '100px'}}>
                  <img className='img-fluid w-100' src={PF + item.profile.pictures} alt='' style={{objectFit: 'cover', cursor: 'pointer'}} />
                </div>
                {item?.startYear} - {item?.endYear}
              </Link>
            </div>
          );
        })}
      </div>
      <div className="section-title">
        <h4 className="m-0 font-weight-bold">University Chapter</h4>
        <Link className="badge badge-primary text-uppercase font-weight-semi-bold mr-1" to='/admin/committees/obuc/add'>+ New Exec</Link>
      </div>
      <div className="row">
        {listObuc?.length>0 && listObuc?.map((item) => {
          return(
            <div key={item.profile.pictures} className='col-lg-2'>
              <Link className="h6 d-block mb-3 text-secondary p-2" to={`/admin/committees/obuc/${item.startYear}`}>
                <div className='position-relative overflow-hidden' style={{maxHeight: '100px'}}>
                  <img className='img-fluid w-100' src={PF + item.profile.pictures} alt='' style={{objectFit: 'cover', cursor: 'pointer'}} />
                </div>
                {item?.startYear} - {item?.endYear}
              </Link>
            </div>
          );
        })}
      </div>
      <div className="section-title">
        <h4 className="m-0 font-weight-bold">Secretariat</h4>
        <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-2" to='' onClick={(e)=> {setReplaceAdmin(true)}} style={{cursor: 'pointer'}} ><i className="singleIcon fa fa-recycle"/> Replace</Link>
      </div>
      { replaceAdmin && 
        <>
            <div className="bg-white align-items-center mb-2 overflow-hidden p-3 border">
                <b>Replace Administrator</b>
            </div>
            <form onSubmit={handleReplaceAdmin}>
                <div className='mb-3 p-2'>
                    <div className="mb-2 row">
                        <div className="col-lg-2">Current: </div>
                        <div className="col-lg-10">
                            <Select placeholder="Search for Name" options={administrators} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => setOldAdmin(e)} />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-lg-2">New: </div>
                        <div className="col-lg-10">
                            <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => setNewAdmin(e)} />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-lg-2">&nbsp;</div>
                        <div className="col-lg-10">
                            <button className="btn btn-primary font-weight-semi-bold px-4" type="submit" disabled={!oldAdmin || !newAdmin}>Replace</button>
                            &nbsp;&nbsp;
                            <button className="btn btn-primary font-weight-semi-bold px-4" onClick={(e) => {setReplaceAdmin(false); setOldAdmin(''); setNewAdmin('');}}>Cancel</button>
                        </div>
                        <hr />
                    </div>
                </div>
            </form>
        </>
      }
      <div className="row mb-3">
        { listAdmins.length>0 && listAdmins.map((item) => {
          return(
            <div key={item.profileId} className='col-lg-5'>
              <div className='position-relative overflow-hidden' style={{maxHeight: '150px'}}>
                <img className='img-fluid w-100' src={PF + item.profile.pictures} alt='' style={{objectFit: 'cover', cursor: 'pointer'}} />
              </div>
              <Link className="h6 d-block mb-1 text-secondary" to={`/profiles/view/${item.profileId}`}>
                {item.profile.profileTitle}
              </Link>
              {item.title}
              
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default CommsAll
