import axios from '../assets/axios'
import useAuth from "./useAuth"

const LOGOUT_URL = '/users/logout'

const useLogout = () => {
    const { setAuth } = useAuth();
    const logout = async () => {
        await axios.get(LOGOUT_URL, {
            withCredentials: true
        });
        setAuth({});
    }
    return logout;
}

export default useLogout