import { Routes, Route } from 'react-router-dom';

import Shelves from './library/Shelves';
import Shelf from './library/Shelf';

function Library({PF}) {
  return (
    <div className='container-fluid pt-5 mb-3'>
      <div className="container">
        <Routes>
            <Route exact path='/' element={<Shelves PF={PF} />} />
            <Route exact path='/view/:id' element={<Shelf PF={PF} />} />
          </Routes>
      </div>
    </div> 
  )
}

export default Library