import {Routes, Route} from 'react-router-dom'

import Confirmation from './shop/pages/Confirmation'
import RequireAuth from '../components/RequireAuth'
import ViewCart from './shop/pages/ViewCart'
import Products from './shop/pages/Products'
import Product from './shop/pages/Product'
import Payment from './shop/pages/Payment'
import './shop/components/Components.scss'
import Home from './shop/pages/Home'
import './shop/pages/Pages.scss'

const Shop = ({PF}) => {
  return (
    <div className="container container-fluid mb-3">
      <Routes>
        <Route exact path='/' element={<Home PF={PF} />} />
        <Route exact path='/products/:id' element={<Products PF={PF}/>} />
        <Route exact path='/product/:id' element={<Product PF={PF} />} />
        <Route element={<RequireAuth allowedRoles={[1, 2, 3, 4, 5]} />}>
          <Route exact path='/payment/:id' element={<Payment PF={PF} />} />
          <Route exact path='/confirmation/:id' element={<Confirmation PF={PF} />} />
          <Route exact path='/cart' element={<ViewCart PF={PF} />} />
        </Route>
      </Routes>
    </div>
  )
}

export default Shop
