import RightSideBar from "../../components/RightSideBar";
import NewProfileForm from "./NewProfileForm";

function AddProfile({PF}) {
  return (
    <div className="container-fluid mt-5 pt-3">
      <div className="container">
        <div className="row">
          <NewProfileForm />          
          <RightSideBar PF={PF} />
        </div>
      </div>
    </div>
  );
}

export default AddProfile