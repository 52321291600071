import { useParams} from "react-router-dom"
import { useState, useEffect } from "react"

import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import CategoryNav from "../components/CategoryNav"

const VIEW_ORDER_URL = '/shop/viewOrder/'

const Payment = ({PF}) => {
  const axiosPrivate = useAxiosPrivate();
  const [order, setOrder] = useState({});
  const [orderItems, setOrderItems] = useState([])
  let {id} = useParams();
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    
    axiosPrivate.get(VIEW_ORDER_URL + id).then((response) => {
      if(isMounted){
        setOrder(response.data.order);
        setOrderItems(response.data.orderItems);
      }
    }).catch((error) => {
        //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [id]);

  return (
    <div className="container container-fluid">
      <CategoryNav />
      <div className="mt-3">
        <h2 className="mb-3 text-secondary font-weight-bold">ORDER # - {id} : TOTAL - {order?.amount}</h2>
      </div>
      <div className="product row">
        <div className="item col-lg-9">
          { orderItems.map((item) => (
            <div className="row mb-2 overflow-hidden col-lg-12" key={item.id} style={{height: '75px'}}>
            <div className="col-lg-2">
              <img src={PF + item.image} className="img-fluid h-100" style={{objectFit: 'cover'}} alt="" />
            </div>
            <div className="col-lg-10">
              <div className="details">
                {item.title}
                <div className="price"><small>{item.quantity} x {item.price.toLocaleString('en')}/-</small></div>
              </div>
            </div>
          </div>
          ))}
          <div className="row mb-2 overflow-hidden" style={{height: '100px'}}>
            <div className="col-2 item">
              <img src={PF + 'Delivery.jpg'} className="img-fluid w-100 h-100" style={{objectFit: 'cover'}} alt="" />
            </div>
            <div className="col-10 item">
              <div className="details">
                Delivery Charges
                <div className="price"><small>5,000/-</small></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
            Your {order?.vendor} payment confirmation for Order# - <b>{order.orderCode}</b> has been received. The Administrator will contact you on when your product(s) will be delivered.
        </div>
        
      </div>
    </div>
  );
};

export default Payment;
