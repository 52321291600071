import { useLocation, Navigate, Outlet } from "react-router-dom"
import jwt_decode from 'jwt-decode'

import useAuth from "../hooks/useAuth"

const RequireAuth = ({ allowedRoles }) => {
    const role = [];
    const { auth } = useAuth();
    const location = useLocation();
    const authDecode = auth?.accessToken ? jwt_decode(auth.accessToken) : undefined;
    role.push(authDecode?.user?.role);
    return (
        role?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : authDecode?.user?.id
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;