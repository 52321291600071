import {useState, useEffect} from 'react'
import Slider from "react-slick"

import useAxiosPrivate from '../../hooks/useAxiosPrivate'

const VIEW_ALERT_URL = '/home/alerts'

function FeatureSlider() {
    const axiosPrivate = useAxiosPrivate();
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_ALERT_URL).then((response) => {
            isMounted && setAlerts(response.data);
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, []);

    var settings = { dots: false, infinite: true, speed: 200, slidesToShow: 1, slidesToScroll: 1, autoplay: true };

    return (
        alerts.length>0 && 
        <div className="container-fluid bg-dark py-2 mb-0">
            <div className="container">
                <div className="row align-items-center bg-dark">
                    <div className="col-11">
                        <div className="d-flex justify-content-between pr-50">
                            <div className="bg-primary text-dark text-center font-weight-medium py-2" style={{width: '200px'}}> <i className="fa fa-bell" aria-hidden="true"/> Alert </div>
                            {alerts.length>1 ?
                            <Slider className="owl-carousel tranding-carousel position-relative d-inline-flex align-items-center ml-3" style={{width: 'calc(100% - 105px)'}} {...settings}>
                                { alerts.map((alert, index)=>{
                                    return(
                                        <div className="text-truncate" key={alert.id}><small className="text-white text-uppercase font-weight-semi-bold">{alert.detail}</small></div>
                                    )
                                })}
                            </Slider>
                            : <div className="text-truncate"><small className="text-white text-uppercase font-weight-semi-bold">{alerts[0].detail}</small></div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeatureSlider