import { Link, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Select from 'react-select'

import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

const COMM_RETIRE_URL = '/admin/committees/trustees/retire/'
const COMM_CHAIR_URL = '/admin/committees/trustees/chair/'
const COMM_ADD_URL = '/admin/committees/trustees/add'
const COMMITTEES_URL = '/admin/committees/trustees/'

function CommsTrustees({PF}) {
    let {id} = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [trustees, setTrustees] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [addMember, setAddMember] = useState(false);
    const [trusteeName, setTrusteeName] = useState('');
    // eslint-disable-next-line
    const [isClearable, setIsClearable] = useState(true);
    // eslint-disable-next-line
    const [isSearchable, setIsSearchable] = useState(true);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(COMMITTEES_URL + id).then((response)=>{
            if(isMounted){ 
                setTrustees(response.data.trustees);
                setProfiles(response.data.profiles);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    const handleTrusteeRetire = async(e, profileId) => {
        e.preventDefault();
        try {
            axiosPrivate.put(COMM_RETIRE_URL + profileId).then(()=>{
              alert('Trustee removed from Committee');
              window.location.reload();
            }).catch( function (error) { console.log(error.config); });   
        }catch (error) { console.log(error); }
    }

    const handleTrusteeChair = async(e, profileId) => {
        e.preventDefault();
        try {
            axiosPrivate.put(COMM_CHAIR_URL + profileId).then(()=>{
              alert('New Chairperson Appointed');
              window.location.reload();
            }).catch( function (error) { console.log(error.config); });   
        }catch (error) { console.log(error); }
    }

    const handleTrusteeDelete = async(e, profileId) => {
        e.preventDefault();         
        try {
            axiosPrivate.delete(COMMITTEES_URL + profileId).then(()=>{
              alert('Removed from List of Trustees');
              window.location.reload();
            }).catch( function (error) { console.log(error.config); });   
        }catch (error) { console.log(error); }
    }

    const handleAddTrustee = async(e) => {
        e.preventDefault();
        const newTrustee = {commId: 102, profileId: trusteeName.value}

        try {
            axiosPrivate.post(COMM_ADD_URL, newTrustee).then(()=>{
              alert('New Board Member Added');
              window.location.reload();
            }).catch( function (error) { console.log(error.config); });   
        }catch (error) { console.log(error); }
    }


    let titleLabel = '';

    if(id==='current'){
        titleLabel = 'Current Members';
    }else if(id === 'former'){
        titleLabel = 'Former Members';
    }

    return (
        <div className="col-lg-8">
            <div className="section-title">
                <h4 className="m-0 text-uppercase font-weight-bold">Trustees - {titleLabel}</h4>
                {id=== 'current' && 
                    <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-1" to='' onClick={(e)=> {setAddMember(true)}}  style={{cursor: 'pointer'}}><i className="fas fa-plus" /> New</Link>
                }                
            </div>
            { addMember && 
                <>
                    <div className="bg-white align-items-center mb-2 overflow-hidden p-3 border">
                        <b>Add New Board Member</b>
                    </div>
                    <form onSubmit={handleAddTrustee}>
                        <div className='row mb-3'>
                            <div className="col-lg-8 mb-3">
                                <Select placeholder="Search for Name" options={profiles} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => setTrusteeName(e)} />
                            </div>
                            <div className="col-lg-4 mb-3">
                                <button className="btn btn-primary font-weight-semi-bold px-4" type="submit" disabled={!trusteeName}>Add</button>
                                &nbsp;&nbsp;
                                <button className="btn btn-primary font-weight-semi-bold px-4" onClick={(e) => setAddMember(false)}>Cancel</button>
                            </div>
                        </div>
                    </form>
                </>
            }
            <div className="row mb-3">
                { trustees && trustees.map((item, index) => {
                    return(
                        <div className='col-lg-4 position-relative mb-3' key={index}>
                            <div className='position-relative overflow-hidden' style={{maxHeight: '120px'}}>
                                <Link className="d-block text-secondary" to={`/profiles/view/${item.profile.id}`}>
                                    <img className='img-fluid w-100' src={PF + item.profile.pictures} alt='' style={{objectFit: 'cover', cursor: 'pointer'}} />
                                </Link>    
                            </div>
                            <small>
                                Name:&nbsp;  
                                <Link className="text-secondary" to={`/profiles/view/${item.profileId}`}>
                                    {item.profile.profileTitle}<br /> 
                                </Link>
                                Position:&nbsp;{item.comm.description}<br />
                                { id=== 'current' && 
                                    <>
                                        <i className="singleIcon fa fa-archive" aria-hidden="true" onClick={(e) => handleTrusteeRetire(e, item.profileId)} style={{cursor: 'pointer'}} />
                                        &nbsp;&nbsp;
                                        { item.commId!== 101 && 
                                            <i className="singleIcon fa fa-arrow-circle-up" aria-hidden="true" onClick={(e) => handleTrusteeChair(e, item.profileId)} style={{cursor: 'pointer'}} />
                                        }
                                    </>
                                }
                                &nbsp;&nbsp;
                                <i className="singleIcon far fa-trash-alt" aria-hidden="true" onClick={(e) => handleTrusteeDelete(e, item.profileId)} style={{cursor: 'pointer'}} />
                            </small>
                        </div>
                    )           
                })}
            </div>
        </div>
    )
}

export default CommsTrustees
