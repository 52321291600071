import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import jwtDecode from 'jwt-decode'

import { removeItem, resetCart } from "../../../redux/cartReducer"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from '../../../hooks/useAuth'

const PLACE_ORDER_URL = '/shop/order'

const ViewCart = ({PF}) => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const products = useSelector((state) => state.cart.products);
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const totalPrice = () => {
    let total = 0;
    products.forEach((item) => {
      total += item.quantity * item.price;
    });
    if (total>0){
      total += 5000;
    }
    return total.toLocaleString('en');
  };
  
  const handleOrder = async (e, products) => {
    e.preventDefault();
    const rnd = (len, chars='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') => [...Array(len)].map(() => chars.charAt(Math.floor(Math.random() * chars.length))).join('')
    let orderCode = rnd(10);

    const newOrderItems = [];   
    const newOrder = {orderCode, amount: totalPrice(), userId: authDecode.user.id};

    for(let i=0; i<products.length; i++){
      let productId = products[i].id;
      let title = products[i].title;
      let image = products[i].img;
      let quantity = products[i].quantity;
      let price = products[i].price;

      newOrderItems.push({productId, title, image, quantity, price, orderCode});
    }
    
    let order = {newOrder, newOrderItems};

    try {
      await axiosPrivate.post(PLACE_ORDER_URL, order).then((response)=>{
        dispatch(resetCart());
        navigate(`/shop/payment/${orderCode}`);
      });   
    }catch (error) {
      console.log(error);  
    }
  };

  return (
    <div className="viewcart container container-fluid pt-5">
      <h2 className="mb-3 text-secondary text-capitalize font-weight-bold">Products in Cart</h2>
      <div className="row">
        <div className="col-lg-8">
          {products?.map((item) => (
            <div className="item row mb-2 overflow-hidden col-lg-12" key={item.id} style={{height: '150px'}}>
              <div className="col-lg-3">
                <img src={PF + item.img} className="img-fluid h-100" style={{objectFit: 'cover'}} alt="" />
              </div>
              <div className="col-lg-7">
                <div className="details">
                  <h1>{item.title}</h1>
                  {item.desc}
                  <div className="price">{item.quantity} x {item?.price.toLocaleString('en')}/-</div>
                </div>
              </div>
              <div className="col-lg-1">
                  <small><i className="fa fa-trash-alt delete" onClick={() => dispatch(removeItem(item.id))} /></small>
              </div>
            </div>
          ))}
          {products.length>0 && 
            <div className="row mb-2 overflow-hidden" style={{height: '150px'}}>
              <div className="col-lg-3 item">
                <img src={PF + 'Delivery.jpg'} className="img-fluid h-100" style={{objectFit: 'cover'}} alt="" />
              </div>
              <div className="col-lg-9 item">
                <div className="details">
                  <h1>Delivery Charges</h1>
                  Deliveries around Kampala will be made every Thursday. For deliveries outside Kampala contact the Administrator for further details
                  <div className="price">5,000/-</div>
                </div>
              </div>
            </div>
          }
        </div>
        <div className="col-lg-4">
          <div className="total"><h6>SUBTOTAL</h6><h6>{totalPrice()}/-</h6></div>
          { products.length>0 &&
            <>
              <button onClick={(e) => handleOrder(e, products)}>PLACE ORDER</button>
              <span className="reset" onClick={() => dispatch(resetCart())}>Reset Cart</span>
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default ViewCart
